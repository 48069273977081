import { Avatar, Button, Space, Tooltip } from "antd";

export const Partners = ({ partnerData }) => {
  const listPartners = partnerData.map((data) => {
    return (
      <Tooltip title={data.title} color={data.color} key={data.key}>
        <Button type="link" target="_blank" rel="noreferrer" href={data.url}>
          <Avatar
            shape="square"
            src={data.thumbnail}
            style={{ width: "100%" }}
          />
        </Button>
      </Tooltip>
    );
  });

  return (
    <div>
      <Space wrap>{listPartners}</Space>
    </div>
  );
};
