import { ViewPdfUpload } from "../../utils/viewPdfUpload";
import { ArticleList } from "../article/articleList";

export const Sltda = () => {
  const articleData = [
    {
      title: "Getting Started",
      key: 1,
      item: [
        {
          key: 1,
          title: "What is the SLTDA?",
          description: (
            <div>
              <p>
                {
                  "The Sri Lanka Tourism Development Authority (SLTDA) is the designated government agency responsible for the planning, development, regulation, and implementation of policies for the tourism and related industries in Sri Lanka."
                }
              </p>
              <p>
                {
                  "The SLTDA serves as your main point of contact for all matters related to development and licensing. They are the go-to organization for addressing any questions or concerns related to the tourism industry."
                }
              </p>
              <p>
                {
                  "Note: Minalka Morahela played a crucial role in setting up the One Stop Shop, she has since moved on and started her own private practice, navigating through the different agencies involved in the approval process for her clients. "
                }
              </p>
              <p>{"Linkedin | email | whatsapp"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "What is the one stop application?",
          description: (
            <div>
              <h1>{"One Stop Unit"}</h1>
              <p>
                {
                  "The One Stop Shop was created to streamline the coordination process with all the relevant agencies. By submitting just one application, it will be distributed to the appropriate authorities for review"
                }
              </p>
              <p>
                {
                  "A site inspection will be conducted by SLTDA and other line agencies. You will be invited to present your project and receive feedback from the various agencies."
                }
              </p>
              <p>
                {
                  "Upon satisfaction of all requirements, you will receive the final approval to proceed with construction. In most cases, the approval process is straightforward. However, the time is mainly spent negotiating the building height with the UDA and the setback from the ocean with the CCD."
                }
              </p>
            </div>
          ),
        },
      ],
    },
    {
      title: "The Process",
      key: 2,
      item: [
        {
          key: 1,
          title: "How does it work?",
          description: (
            <div>
              <p>{"Test"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "One Stop Shop - Application Form",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/sltda/sltda-common-application-form-2011.pdf"
                }
              />
            </div>
          ),
        },
        {
          key: 3,
          title: "Completed - Application Form",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/sltda/final-application-submission-after-comments.pdf"
                }
              />
            </div>
          ),
        },
        { key: 4, title: "The Feedback", description: "application" },
        {
          key: 5,
          title: "The Final Approval",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/sltda/2013+09+11+Final+Approval+Sri+Lanka+Tourism.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
    { title: "Contacts", key: 4 },
  ];

  return <ArticleList articleData={articleData} />;
};
