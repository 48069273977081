import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { NotFound } from "./utils/notFound";
import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import { Directory } from "./components/directory/directory";
import { Images } from "./components/images/model";
import { Sltda } from "./components/articleData/sltda";
import { Template } from "./components/articleData/template";
import { Team } from "./components/team/Team";
import { Budget } from "./components/budget/Budget";
import { StartHere } from "./components/articleData/startHere";
import { Model } from "./components/articleData/model";
import { Marriott } from "./components/articleData/marriott";
import { Research } from "./components/articleData/research";
import { Footer } from "./components/navigation/footer";
import { Summary } from "./components/articleData/summary";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/template" element={<Template />} />
        <Route exact path="/start-here" element={<StartHere />} />
        <Route exact path="/directory" element={<Directory />} />
        <Route exact path="/marriott" element={<Marriott />} />
        <Route exact path="/research" element={<Research />} />
        <Route exact path="/images" element={<Images />} />
        <Route exact path="/sltda" element={<Sltda />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/budget" element={<Budget />} />
        <Route exact path="/model" element={<Model />} />
        <Route exact path="/summary" element={<Summary />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
