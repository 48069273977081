import { Col, Row, Image, Button } from "antd";

export const Images = () => {
  const imageData = [
    {
      key: 1,
      src: "https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-suite-0039-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1215px:*",
      title: "king room looking at the balcony",
    },
    {
      key: 2,
      src: "https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-suite-0029-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1215px:*",
      title: "king room looking at the balcony",
    },
    {
      key: 3,
      src: "  https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-suite-0038-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1215px:*  ",
      title: "  ",
    },
    {
      key: 4,
      src: "  https://www.marriottsouthasiaresorts.com/resourcefiles/hotelprofile/weligama-bay-marriott-resort-spa1-th.jpg?version=9162022083956  ",
      title: "  ",
    },
    {
      key: 5,
      src: "   https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-balcony-0037-hor-wide.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1336px:* ",
      title: "  ",
    },
    {
      key: 6,
      src: "  https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-guestroom-0034-hor-wide.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1336px:*  ",
      title: "  ",
    },
    {
      key: 7,
      src: "  https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-suite-0041-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1215px:* ",
      title: "  ",
    },
    {
      key: 8,
      src: "  https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-lobby-0024-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1215px:*  ",
      title: "  ",
    },
    {
      key: 9,
      src: "  https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-weligama-0018-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1215px:*  ",
      title: "  ",
    },
    {
      key: 10,
      src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGOesGXKFHRTUGdruiD9dBOGtPKFVjPtFmaw&usqp=CAU    ",
      title: "",
    },
    {
      key: 11,
      src: " https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-tides-0020-hor-clsc.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=1846px:*   ",
      title: "  ",
    },
    {
      key: 12,
      src: "  https://cache.marriott.com/content/dam/marriott-renditions/CMBMC/cmbmc-ballroom-0048-hor-wide.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=2882px:*  ",
      title: "  ",
    },
    {
      key: 13,
      src: "  http://dailynews.lk/sites/default/files/news/2017/10/03/z_pv-World-07.jpg  ",
      title: "  ",
    },
    {
      key: 14,
      src: " https://images.squarespace-cdn.com/content/v1/52ed3507e4b041396187d22b/1605595264296-CGHTWJHYKB6JDM5LMUEM/image-asset.jpeg?format=500w   ",
      title: "  ",
    },
  ];

  const listImageData = imageData.map((data) => {
    return (
      <Col xs={24} sm={6} md={8}>
        <Image alt={data.title} src={data.src} />
      </Col>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: "5px",
      }}
    >
      <Row
        gutter={[4, 4]}
        style={{ textAlign: "center", maxWidth: "700px", display: "flex" }}
      >
        <Col span={24}>
          <Button>King Room</Button>
          <Button>Twin Room</Button>
          <Button>Spa</Button>
          <Button>All Day Dinning</Button>
          <Button>Speciality Dining</Button>
          <Button>Cafe</Button>
          <Button>Swimming Pool</Button>
          <Button>Ballroom</Button>
          <Button>Meeting Rooms</Button>
        </Col>
        <Image.PreviewGroup>{listImageData}</Image.PreviewGroup>
      </Row>
    </div>
  );
};
