import { Col, Row, Avatar, List, Button } from "antd";
import { useState } from "react";
import {
  directoryFeasibility,
  directoryDesign,
  directoryFinance,
  directoryContractors,
  directorySupplier,
  directoryGovernement,
} from "./directoryData";

import { ViewItem } from "./viewItem";

export const Directory = () => {
  const allDirectory = [
    ...directoryDesign,
    ...directoryFeasibility,
    ...directoryFinance,
    ...directoryContractors,
    ...directorySupplier,
    ...directoryGovernement,
  ];
  const directoryButtons = [
    {
      title: "all",
      state: allDirectory,
      key: 1,
      subDirectory: [],
    },
    {
      title: "feasibility",
      state: directoryFeasibility,
      key: 2,
      subDirectory: [],
    },
    {
      title: "design",
      state: directoryDesign,
      key: 3,
      subDirectory: [
        { title: "interior design", key: 1 },
        { title: "structural", key: 2 },
      ],
    },
    {
      title: "finance",
      state: directoryFinance,
      key: 4,
      subDirectory: [],
    },
    {
      title: "government",
      state: directoryGovernement,
      key: 5,
      subDirectory: [],
    },
    {
      title: "contractor",
      state: directoryContractors,
      key: 6,
      subDirectory: [
        { title: "furniture", key: 1 },
        { title: "doors & windows", key: 2 },
        { title: "stones, marble & granite", key: 3 },
        { title: "ceiling & walls", key: 4 },
        { title: "mep", key: 5 },
      ],
    },
    {
      title: "supplier",
      state: directorySupplier,
      key: 7,
      subDirectory: [],
    },
  ];

  const [directoryItem, setDirectoryItem] = useState(allDirectory[0]);
  const [directoryList, setDirectoryList] = useState(allDirectory);
  const [subDirectory, setSubDirectory] = useState(
    directoryButtons[0].subDirectory
  );

  const listButtons = directoryButtons.map((data) => {
    return (
      <Button
        key={data.key}
        style={{
          margin: "2px",
          backgroundColor: directoryList === data.state ? "pink" : "#fff",
        }}
        onClick={() => {
          setDirectoryList(data.state);
          setDirectoryItem(data.state[0]);
          setSubDirectory(data.subDirectory);
        }}
      >
        {data.title}
      </Button>
    );
  });

  const listSubDirectoryButtons = subDirectory.map((data) => {
    const subDirectoryData = allDirectory.filter(
      (item) => item.subDirectory === `${data.title}`
    );

    return (
      <Button
        type="link"
        key={data.key}
        style={{
          margin: "2px",
          // backgroundColor: subDirectoryData === directoryList ? "pink" : "#fff",
        }}
        onClick={() => {
          setDirectoryList(subDirectoryData);
          setDirectoryItem(subDirectoryData[0]);
        }}
      >
        <small>{data.title}</small>
      </Button>
    );
  });

  return (
    <div
      style={{
        marginTop: "10px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Row
        gutter={[18, 4]}
        style={{
          maxWidth: "900px",
          display: "flex",
          width: "100%",
        }}
      >
        <Col span={24} style={{ textAlign: "center" }}>
          {listButtons}
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          {listSubDirectoryButtons}
        </Col>
        <Col xs={24} md={12}>
          <List
            itemLayout="horizontal"
            dataSource={directoryList}
            style={{
              minHeight: "60vh",
            }}
            pagination={{
              pageSize: 10,
              align: "center",
              simple: true,
              defaultCurrent: 1,
              hideOnSinglePage: true,
            }}
            renderItem={(item, index) => (
              <List.Item>
                <div
                  style={{
                    magrin: "0px",
                    padding: "0px",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDirectoryItem(item);
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar shape="square" src={item.thumbnail} />}
                    title={item.title}
                    description={item.description}
                  />
                </div>
              </List.Item>
            )}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          style={{ border: "2px black block", borderRadius: "2px" }}
        >
          <ViewItem directoryItem={directoryItem} />
        </Col>
      </Row>
    </div>
  );
};
