import { ViewPdfUpload } from "../../utils/viewPdfUpload";
import { ArticleList } from "../article/articleList";

export const Summary = () => {
  const articleData = [
    {
      title: "Summary",
      key: 1,
      item: [
        {
          key: 1,
          title: "Return on Investment",
          description: (
            <div>
              <p>{"The hotel sold in April 19th 2019"}</p>
              <p>{"Enterpise Value: USD57,000,000"}</p>
              <p>{"Per Key Value: USD287,000"}</p>
              <p>{"Total of Development: USD42,000,000"}</p>
              <p>{"Profit: USD18,000,000"}</p>
              <p>{"Total Equity Contribution: USD15,000,000"}</p>
              <p>{"ROI - 120%"}</p>
              <p>{"Notes on the sale of the hotel:"}</p>
              <p>{"Singaporean company: Hotel Properties Ltd. Group"}</p>
              <p>
                <a
                  target={"_blank"}
                  rel={"noreferrer"}
                  href="https://www.ft.lk/front-page/S-pore-giant-buys-Nahil-s-Marriott-Weligama-Bay-Resort---Spa-for-Rs--4-b/44-676453"
                >
                  {
                    " Financial Times - Sri Lanka: S’pore giant buys Nahil’s Marriott Weligama Bay Resort & Spa for Rs. 10 b"
                  }
                </a>
              </p>
            </div>
          ),
        },
        {
          key: 2,
          title: "Conclusion",
          description: (
            <div>
              <p>
                {
                  "At the very onset we had a clear vision for the project. Build add value sell "
                }
              </p>
            </div>
          ),
        },
        {
          key: 1,
          title: "Article with PDF",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
  ];

  return <ArticleList articleData={articleData} />;
};
