export const mainSectionData = [
  {
    key: 1,
    title: "Start here",
    thumbnail: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          fill="#2196f3"
          d="M37,45H11c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h19l10,10v29C40,43.657,38.657,45,37,45z"
        ></path>
        <path fill="#bbdefb" d="M40 13L30 13 30 3z"></path>
        <path fill="#1565c0" d="M30 13L40 23 40 13z"></path>
        <path
          fill="#e3f2fd"
          d="M15 23H33V25H15zM15 27H33V29H15zM15 31H33V33H15zM15 35H25V37H15z"
        ></path>
      </svg>
    ),
    link: "start-here",
  },
  {
    key: 1,
    title: "Directory",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiNGRjcwNDMiIGQ9Ik0zOCw0NEgxMlY0aDI2YzIuMiwwLDQsMS44LDQsNHYzMkM0Miw0Mi4yLDQwLjIsNDQsMzgsNDR6Ij48L3BhdGg+PHBhdGggZmlsbD0iI0JGMzYwQyIgZD0iTTEwLDRoMnY0MGgtMmMtMi4yLDAtNC0xLjgtNC00VjhDNiw1LjgsNy44LDQsMTAsNHoiPjwvcGF0aD48cGF0aCBmaWxsPSIjQUIzMDBCIiBkPSJNMjYgMTZBNCA0IDAgMSAwIDI2IDI0IDQgNCAwIDEgMCAyNiAxNnpNMzMgMzBjMCAwLTEuOS00LTctNC01LjEgMC03IDQtNyA0djJoMTRWMzB6Ij48L3BhdGg+Cjwvc3ZnPg=="
      />
    ),
    link: "directory",
  },
  {
    key: 1,
    title: "Marriott",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+Cjxwb2x5Z29uIGZpbGw9IiNiNzFjMWMiIHBvaW50cz0iMTEsOSAxOSw2IDM0LDQwIDI1LDQwIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iI2I3MWMxYyIgcG9pbnRzPSIyNS4zOTcsOS44NDIgMzIuOTc4LDYuMjIzIDQ4LDQwIDM5LDQwIj48L3BvbHlnb24+PHJlY3Qgd2lkdGg9IjExLjAxMSIgaGVpZ2h0PSIyIiB4PSIyNC43NTgiIHk9IjI0LjIyNiIgZmlsbD0iI2I3MWMxYyIgdHJhbnNmb3JtPSJyb3RhdGUoLTY1LjYgMzAuMjYzIDI1LjIyNSkiPjwvcmVjdD48cGF0aCBmaWxsPSIjYjcxYzFjIiBkPSJNMCw0MmwzLjk3My03LjU1NGMwLDAsMS45MjIsMS4wNDQsNC45ODktMC42ODVjNC41OTgtMi41OTIsOC43NTUtMTEuNzg4LDguNzU1LTExLjc4OGwxLjE3NCwxLjY2MwljMCwwLTUuNTQzLDE5LjM3LTE0LjgwNCwxOS4zN0MwLjc2MSw0My4wMDUsMCw0MiwwLDQyeiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "marriott",
  },
  {
    key: 1,
    title: "Research",
    thumbnail: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          fill="#2196f3"
          d="M37,45H11c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h19l10,10v29C40,43.657,38.657,45,37,45z"
        ></path>
        <path fill="#bbdefb" d="M40 13L30 13 30 3z"></path>
        <path fill="#1565c0" d="M30 13L40 23 40 13z"></path>
        <path
          fill="#e3f2fd"
          d="M15 23H33V25H15zM15 27H33V29H15zM15 31H33V33H15zM15 35H25V37H15z"
        ></path>
      </svg>
    ),
    link: "research",
  },
  {
    key: 3,
    title: "budget",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiM0M2EwNDciIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYzhlNmM5IiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMmU3ZDMyIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2U4ZjVlOSIgZD0iTTMxLDIzSDE3aC0ydjJ2MnYydjJ2MnYydjJoMTh2LTJ2LTJ2LTJ2LTJ2LTJ2LTJ2LTJIMzF6IE0xNywyNWg0djJoLTRWMjV6IE0xNywyOWg0djJoLTRWMjl6IE0xNywzM2g0djJoLTRWMzN6IE0zMSwzNWgtOHYtMmg4VjM1eiBNMzEsMzFoLTh2LTJoOFYzMXogTTMxLDI3aC04di0yaDhWMjd6Ij48L3BhdGg+Cjwvc3ZnPg=="
      />
    ),
    link: "budget",
  },
  {
    key: 2,
    title: "Images",
    thumbnail: (
      <img
        alt="outline"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2klEQVR4nO2XP0vDQBTAf0q7dHMwi6uIu6Cr6KIILo7ubo4unfwI9RM4iJ+g+AWsIli3ulusg+hWBMV/TwJvCCVp7pJrver94GiaXO7eL/fupYVAIPDvuATEk9YqIyKetcKUHsAREkSUsCKWbAEPQA/YNHigYlsATFdkTivcNTCDPb3EXPcGcYhttph0XgS6ib6xUI3RiuBaZBl4Tgjc6fEZUDWdRNOppxIb4xZZB/p6vamrMA886rlTYNpCxjYOcSGyC7zrtWOgkri2lBBsFI89Nw6xKQBpA+wDX3r+CJhKuW8VeNU+9TGLSN4AccCH+v0bOMiZfBv40P6x/DhSS/JEKppCoikVp5YJeyr9CewM6RcBF0Bbj0cm0tTPl4yqMoy63vsGrGVIdBJzdVJkxJWIaJldoRgNHaOvxSBNojNwHI1CpKsvvqLE++tEx3oCFlIkooxzTkXinyBlqeqLUvTFeZvx9NNkxJWIK2oD/zrT9kOajPgmkpU6eX3FJ5FBiVnLe8QXkbbhSgwS+SZyBdxYSgyL41f3SFEkiChhRRwjIbUU8awVrlotD4IXbecGQfuyp62QIOLZ8knZFZm0ApDJpBWAiUSCiGdPQsrG4XMBsMLnAhAIBP46P102ab5xGRTCAAAAAElFTkSuQmCC"
      />
    ),
    link: "images",
  },

  {
    key: 4,
    title: "Summary",
    thumbnail: (
      <img
        alt={"summary logo"}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAqElEQVR4nO3YMQ6AIAyF4d7/WHYwnqoO6KZBimJp/i9hJb600KAI5mLBllu6IH8zghyoyMuM1vq4tTYRWTNUxBr3ta+CaOP9rw/2PffUyEGWhiDLyCBpWqsXQbyoSAWt5cUcEebIJebIieu3guvXK31rKe+RgveIV/oz0osgXlRkltZS5kjBHPHisFfoza/R6Qbi8O8wgrzLqMiB1oraWhZkuaUJAhlsB0+566uSDGYdAAAAAElFTkSuQmCC"
      />
    ),
    link: "summary",
  },
  {
    key: 6,
    title: "Our Team",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEsUlEQVR4nO2ZaYhXVRjGf02TZXvkGGYSfpNpQaTFrKgoKDI1sz4ELSoEFi1CQfahxcoorOhDaIZpYpstVBQEjllC2maW0sbU0GaJbWOmZovdeOM58XK799zlP+UI94HD3P89z/ves7znXc5AgwYNGjSA3YBTgHuAlcBG4FegG9h9V1mgc4D3gSSnXUI/Rzswzw34R2AucD4wEpim973AAuBU+qkpPamBmgndBOyZMdFuN9E/gbvpZ7hGg9sCjCngDgGuArZJ5gr+Z7QBp8l83gU2aFXHAd9rUBMq6LtQMj8AM4DngR5gk96f/F9MohN4I+fw/qK/XTX0vhZxCl3A4X05idHODL4F7gPGA6Pc6lmbVEP3ZZL9FLgUOE6OISyOtZeAoa1OokMxwBQ+BhyQ6j8d+En9ZvtVcbRkP0y9nwrMl/la/5oM51EJs9w25wWyb8QZWEP/Qc4l5/V/JM50WsAnUnJshPO5OLZ7VXGgZG1X8zDe7Uot7A3sUGCzOJGHp/Wh82p84wTJro1wBgC/q9Uyr330EQtiMVwr3uIa33hCsrMLeF+KN6xu3NiibY8lesv0kQcr6rddXijZpQXc78Q7hJpYWiI4/SzOoTX0D5WsecY8dIpj57U21krJHRHOanHMFVfFGZI1HXm4XxwLnrWwrxRsVsKXhxvE6ymRZ3mMkYzJXh/h7Qf8IccTG0f0jPTqQ2fqdxbMk6wQb0kF/Usks6LAG50k3voC7xnFHJcqxCJ3hxLIjXKXRRigdMdWeVCEd5h2w74/kxZgK/VKycw2JJWTS+idIu6qAt5FzquVWaAopkvZiyXT8g0FqzzI5VAmk4d24L0SvNLocInhlRGe2e/yEq4ypD3LC2z+cedEWt6NgOuk9IUC3mB3pvIQ+o1LCd5o+hCTpPS5EtyyE2lVTy14+78A2D/iHMIAhmf0D3f9eS53sC4v+nwi5nbfzilH04dzvuvrVaAbpjbDxSVrD2UEuCSj3RiJYaVg9fKd7uOfAZcDr+rKJ0ml42/p3XaXSGa1ZeIkWqATUxPZrvJ2tpNZrYu9vapMwAb1rAtEoS1ynNf1bmLGBUJIyc9ySWeIBfaO1CBDPAnn0HSj+j29CJYF31VUVg9RrEjcyixWwfSbJjZCqxLqg8Rd6azS88OpfCpwfB62SO9Wuquk0L5S2Xyvfs9RDe/Ne5us5V9u+Rjga1d23prK/eeq703gC6ewWxdsVoSNdblTwETHteeAsItnqxK169SPHXe9q0GOd3JWcj+l1CZRNvHP7hzsIm1XTu0daoLQ1mlgbakzlag+2UPvwn1vome0ilszKj7TNU6Xf0Fmq+r6rIXvcYv7d+E309lme046/47brckRLxJMzs6Z4WY3KHtGhzvRzmahDbjY3ZmtUSqfRoezoim+eMq7YFvoLtFsZ2IItm9xIJ0927PhloyzlIUR7tLbOxqPq9X/MqrLk4Jm7vYIihHOhP1/xPCM02HPhg/0+9wS+jqdq481u+kpJFl7gHIYqIrSZI5MuWZ7PkrPmyvEhHklxxjFuhq37I9K5jZ3IINp3q7nRyrom+AcTG34S+qd3Ta1MpGdPfikivk0aNCgAbsM/gJvAihHGIEy4AAAAABJRU5ErkJggg=="
      />
    ),
    link: "team",
  },
];

export const subSectionDataGovernment = [
  {
    key: 1,
    title: "BOI",
    thumbnail: (
      <img
        width={"45px"}
        alt="BOI logo"
        src="https://investsrilanka.com/wp-content/uploads/2020/11/BOI-LOGO--150x150.jpg"
      />
    ),
    link: "boi",
  },
  {
    key: 7,
    title: "SLTDA",
    thumbnail: (
      <img
        alt="svgImg"
        width={"50px"}
        src="https://www.sltda.gov.lk/images/sltda-logo.png"
      />
    ),
    link: "sltda",
  },
  {
    key: 1,
    title: "UDA",
    thumbnail: (
      <img
        width={"50px"}
        alt="svgImg"
        src="https://applications.uda.lk/images/logo/logo.png"
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "CCD",
    thumbnail: (
      <img
        width={"50px"}
        alt="svgImg"
        src="https://scontent.ftlv20-1.fna.fbcdn.net/v/t39.30808-6/250956516_260632589444760_5357186661249113414_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=C6_IcYYXUkYAX8WHqxu&tn=p2cEfBjZz7pL3xnH&_nc_ht=scontent.ftlv20-1.fna&oh=00_AfAWmY1sW8ZFgnw936iyTU-pOeXzfcWmRPDWgqmEB1YhsQ&oe=63EDD1D6"
      />
    ),
    link: "template",
  },
];

export const subSectionDataArchitecture = [
  {
    key: 1,
    title: "Architecture",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "Interiors",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "Landscaping",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "facade",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 5,
    title: "Model",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqklEQVR4nO2ZsUoDQRCGP4jplFiJmCKvIIrgK4iVXd5CFO19B60kPoSiINrZpBDFIiLWCvYStTGOLMzBEjS3G7ncnOwPQ8hlJ/wf9zMHNwA3gBipLtBiTImxegFW/gryCCxQjmaAM/XxAbRj/yCDePA+5ylHdeBQfQyAnXFA5oCegTvjtKkgzktHAYNBMAazAbypl0ugEQNiDWYReFIvvbyJNgxiDaYJ3IZMtJ9ArMFMA6d5E+03EGswNWBfvXwBezEg1mBGTrQ8EIswa8Cr+rnIJloICPqQzB6a1uo6BiSDEaNFDMg454uWJBDSHSlEkqJFilYhkhQtUrQKkaRokaJViCRFixStQiQpWqRoFSL5t9FqxDYakWR+ut77oXqVQZrAs345imk0IvH9LAN9vbAb02hAMuxnHfjUF8TtKoOgOzt38R1YpcIgTgfeUqVVZZAacKI/3gOzoY0lSUb5cTvvOz1wDkyFNpYgyfPT9BaRnZjGCUtC/Cx5Y3k7pnGCklA/2Vge6O67siBOW3q4X3UQvI1q5UFqwHFJazUJqCi5sewWj2WbHq6rb0IZBQCSUUpGAAAAAElFTkSuQmCC"
      />
    ),
    link: "model",
  },
];

export const subSectionDataServices = [
  {
    key: 1,
    title: "Stuctural",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "Plumbing",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "HVAC",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "Electrical",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "ELV",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "Fire Life & Safety",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "Vertcial Transport",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 1,
    title: "STP",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
];

export const subSectionDataFinance = [
  {
    key: 1,
    title: "Debt",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 2,
    title: "Private Equity",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 3,
    title: "Sale",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
];
export const subSectionDataInterestingStuff = [
  {
    key: 1,
    title: "CNC Work",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 2,
    title: "Boring Work",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 3,
    title: "Casting",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 3,
    title: "Block Making",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
  {
    key: 3,
    title: "ReadyMix",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMyMTk2ZjMiIGQ9Ik0zNyw0NUgxMWMtMS42NTcsMC0zLTEuMzQzLTMtM1Y2YzAtMS42NTcsMS4zNDMtMywzLTNoMTlsMTAsMTB2MjlDNDAsNDMuNjU3LDM4LjY1Nyw0NSwzNyw0NXoiPjwvcGF0aD48cGF0aCBmaWxsPSIjYmJkZWZiIiBkPSJNNDAgMTNMMzAgMTMgMzAgM3oiPjwvcGF0aD48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNMzAgMTNMNDAgMjMgNDAgMTN6Ij48L3BhdGg+PHBhdGggZmlsbD0iI2UzZjJmZCIgZD0iTTE1IDIzSDMzVjI1SDE1ek0xNSAyN0gzM1YyOUgxNXpNMTUgMzFIMzNWMzNIMTV6TTE1IDM1SDI1VjM3SDE1eiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    link: "template",
  },
];
