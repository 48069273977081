import { Button, Col, Row, Modal } from "antd";
import { useState } from "react";
import { FullscreenOutlined } from "@ant-design/icons";
// ---- PDF plugin ----

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

export const ViewPdfUpload = ({ pdfURL }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Row justify="center">
      <Col span={24} style={{ textAlign: "right" }}>
        <>
          <Button onClick={showModal} type="link">
            <FullscreenOutlined
              style={{ verticalAlign: "middle", margin: "2px" }}
            />
            {<small>{"full-screen"}</small>}
          </Button>
          <Modal
            title=""
            // zIndex="999910"
            scrollable
            open={isModalOpen}
            onOk={handleOk}
            width={1000}
            style={{
              height: "80vh",
              top: "100px",
            }}
            onCancel={handleCancel}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.js">
              <div
                style={{
                  margin: "25px",
                  paddingLeft: "10px",
                  height: "70vh",
                  overflow: "auto",
                }}
              >
                <Viewer
                  fileUrl={pdfURL}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          </Modal>
        </>
      </Col>
      <Col span={24}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.js">
          <div style={{ height: "60vh" }}>
            <Viewer fileUrl={pdfURL} plugins={[defaultLayoutPluginInstance]} />
          </div>
        </Worker>
      </Col>
    </Row>
  );
};
