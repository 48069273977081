import { Button, Card, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Partners } from "../components/partners/Partners";
import {
  mainSectionData,
  subSectionDataArchitecture,
  subSectionDataFinance,
  subSectionDataGovernment,
  subSectionDataInterestingStuff,
  subSectionDataServices,
} from "../components/main/mainData";
import { SupportUs } from "../utils/suppportUs";
import {
  designPartnerData,
  financePartnerData,
  mainPartnerData,
  servicesPartnerData,
} from "../components/partners/partnerData";

export const Main = () => {
  const [showOptions, setShowOptions] = useState(false);

  const listSectionData = mainSectionData.map((data) => {
    return (
      <Col xs={12} sm={3} md={6}>
        <Link to={data.link} style={{ textDecoration: "none" }}>
          <Card
            title={<div style={{ padding: "10px" }}>{data.thumbnail}</div>}
            bordered={true}
            style={{ textAlign: "center" }}
            hoverable
          >
            <span style={{ fontFamily: "inherit" }}>{data.title}</span>
          </Card>
        </Link>
      </Col>
    );
  });

  const ListSubSectionData = ({ subSectionData }) => {
    return subSectionData.map((data) => {
      return (
        <Col xs={12} sm={3} md={6}>
          <Link to={data.link} style={{ textDecoration: "none" }}>
            <Card
              size="small"
              title={<div style={{ padding: "10px" }}>{data.thumbnail}</div>}
              bordered={true}
              style={{ textAlign: "center" }}
              hoverable
            >
              <span style={{ fontFamily: "inherit" }}>{data.title}</span>
            </Card>
          </Link>
        </Col>
      );
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "5px",
        }}
      >
        <Row
          style={{ textAlign: "center", maxWidth: "1400px", display: "flex" }}
        >
          <Col span={24} style={{ zIndex: "999" }}>
            <div>
              <h1 style={{ color: "#0842a0", fontSize: "2rem" }}>
                Weligama Bay - Marriott Resort & Spa
              </h1>
              <h2 style={{ color: "grey", fontSize: "1.5rem" }}>
                {"- case study -"}
              </h2>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row
          style={{
            textAlign: "center",
            maxWidth: "1000px",
            position: "absolute",
            top: "50px",
          }}
        >
          <Col span={24}>
            <img
              style={{ borderRadius: "5px" }}
              alt="marriott"
              width={"100%"}
              src={
                "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/view-weligama-bay-01.jpg"
              }
            />
          </Col>
        </Row>
      </div>
      <Divider></Divider>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row style={{ textAlign: "center", maxWidth: "1000px" }}>
          <Col span={24}>
            <Partners partnerData={mainPartnerData} />
          </Col>
          <Divider />
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row style={{ textAlign: "center", maxWidth: "1000px" }}>
          <Col span={24}>
            <SupportUs />
          </Col>
          <Divider />
        </Row>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Row gutter={[4, 4]} style={{ maxWidth: "800px", margin: "5px" }}>
          {listSectionData}
        </Row>
      </div>
      <Divider>
        <Button
          onClick={() => {
            setShowOptions(!showOptions);
          }}
          type="link"
        >
          {showOptions ? (
            <UpCircleOutlined
              style={{
                fontSize: "22px",
              }}
            />
          ) : (
            <DownCircleOutlined style={{ fontSize: "22px" }} />
          )}
        </Button>
      </Divider>
      {showOptions && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Row gutter={[4, 4]} style={{ maxWidth: "800px", margin: "5px" }}>
            <Divider orientation="left" orientationMargin="5">
              Government
            </Divider>
            <ListSubSectionData subSectionData={subSectionDataGovernment} />
            <Divider orientation="left" orientationMargin="5">
              Design
            </Divider>
            <Col span={24} style={{ margin: "10px", textAlign: "center" }}>
              <Partners partnerData={designPartnerData} />
            </Col>
            <ListSubSectionData subSectionData={subSectionDataArchitecture} />
            <Divider orientation="left" orientationMargin="5">
              Services
            </Divider>
            <Col span={24} style={{ margin: "10px", textAlign: "center" }}>
              <Partners partnerData={servicesPartnerData} />
            </Col>
            <ListSubSectionData subSectionData={subSectionDataServices} />
            <Divider orientation="left" orientationMargin="5">
              Financing
            </Divider>
            <Col span={24} style={{ margin: "10px", textAlign: "center" }}>
              <Partners partnerData={financePartnerData} />
            </Col>
            <ListSubSectionData subSectionData={subSectionDataFinance} />
            <Divider orientation="left" orientationMargin="5">
              Interesting Stuff!
            </Divider>
            <Col
              span={24}
              style={{ margin: "10px", textAlign: "center" }}
            ></Col>
            <ListSubSectionData
              subSectionData={subSectionDataInterestingStuff}
            />
          </Row>
        </div>
      )}
    </div>
  );
};
