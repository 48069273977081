import { Button, message } from "antd";
import { SupportUsArticle } from "./suppportUs";

export const ContentUseFul = ({ viewArticle }) => {
  console.log(viewArticle);
  const success = () => {
    message.success({
      type: "success",
      content: "Thanks for your feedback!",
      style: { marginTop: "5vh" },
    });
  };
  const onClick = (response) => {
    console.log(response);
    success();
  };
  return (
    <small>
      <p style={{ margin: "0px" }}>
        Was this helpful?
        <Button
          onClick={() => {
            onClick(
              `yes section ${window.location.pathname} - "${viewArticle.title}" was useful`
            );
          }}
          size="small"
          style={{ margin: "0px 5px" }}
        >
          Yes
        </Button>
        <Button
          style={{ margin: "0px 5px" }}
          onClick={() => {
            onClick(`no section "${viewArticle.title}" was useful`);
          }}
          size="small"
        >
          No
        </Button>
        <SupportUsArticle />
      </p>
    </small>
  );
};
