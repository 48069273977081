import { Col, Divider, Image, Row } from "antd";

export const Model = () => {
  const modelData = [
    {
      alt: "thread bar model of hotel",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+002.jpg",
    },
    {
      alt: "thread bar model of hotel",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+016.jpg",
    },
    {
      alt: "thread bar model of hotel",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+024.jpg",
    },
    {
      alt: "thread bar model of hotel",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+025.jpg",
    },
    {
      alt: "thread bar model of hotel",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+034.jpg",
    },
    {
      alt: "thread bar model of hotel",
      src: "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+036.jpg",
    },
  ];

  const listImageData = modelData.map((data) => {
    return (
      <Col xs={24} sm={6} md={8}>
        <Image alt={data.alt} src={data.src} />
      </Col>
    );
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "5px",
        }}
      >
        <Row
          style={{ textAlign: "center", maxWidth: "1200px", display: "flex" }}
        >
          <Col span={24} style={{ zIndex: "9999" }}>
            <div>
              <h1 style={{ color: "#0842a0", fontSize: "2rem" }}>
                Weligama Bay - Marriott Resort & Spa
              </h1>
              <h2 style={{ color: "grey", fontSize: "1.5rem" }}>
                {"- 3d models -"}
              </h2>
            </div>
          </Col>
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0">
              SketchUp
            </Divider>
            <iframe
              src="https://3dwarehouse.sketchup.com/embed/cf6b54aa-658c-4f13-baad-f1368b21e0b1?token=JWuLaG93Qq8=&binaryName=s21"
              title="marriott-weligama-bay-sketch-up-model"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="600px"
              allowfullscreen
            ></iframe>
          </Col>
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0">
              {"3D Model"}
            </Divider>
            <Row gutter={[18, 18]}>
              <Col span={24}>
                <div style={{ textAlign: "left" }}>
                  <p>
                    {
                      " Built a 3d Model out of perspex and threads - the scale was 100:1 "
                    }
                  </p>
                </div>
              </Col>
              <Image.PreviewGroup>{listImageData}</Image.PreviewGroup>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
