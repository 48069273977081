import { Button, Col, Collapse, List, Row } from "antd";
import { useState } from "react";
import { ViewArticle } from "./viewArticle";

export const ArticleList = ({ articleData }) => {
  const { Panel } = Collapse;

  const [viewArticle, setViewArticle] = useState({
    description: articleData[0].item[0].description,
    title: articleData[0].item[0].title,
  });
  const listPanel = articleData.map((data) => {
    return (
      <Panel header={data.title} key={data.key}>
        <List
          size="small"
          bordered={false}
          dataSource={data.item}
          renderItem={(item, index) => (
            <Button
              style={{
                textDecoration: "none",
                margin: "2px",
                padding: "0px",
                width: "100%",
              }}
              type="link"
              href={`#${item.title}`}
              onClick={() => {
                console.log(item, index);
                setViewArticle({
                  description: item.description,
                  title: item.title,
                });
              }}
            >
              <List.Item
                style={{
                  borderBottom: "solid 0.5px rgba(0, 0, 0, 0.88)",
                  padding: "5px",
                }}
              >
                {item.title}
              </List.Item>
            </Button>
          )}
        />
      </Panel>
    );
  });
  return (
    <div style={{ minHeight: "70vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Row
          gutter={[18, 18]}
          style={{ maxWidth: "1200px", display: "flex", width: "100%" }}
        >
          <Col sm={24} xs={24} md={12}>
            <p>Browse help topics</p>
            <Collapse defaultActiveKey={["1"]} onChange={""}>
              {listPanel}
            </Collapse>
          </Col>
          <Col sm={24} xs={24} md={12}>
            <ViewArticle viewArticle={viewArticle} />
          </Col>
        </Row>
      </div>
    </div>
  );
};
