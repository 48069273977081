import { ViewPdfUpload } from "../../utils/viewPdfUpload";
import { ArticleList } from "../article/articleList";

export const Research = () => {
  // Important
  // To be used as a template for articles
  // copy the file and edit

  const articleData = [
    {
      title: "Research & Feasibility",
      key: 1,
      item: [
        {
          key: 1,
          title: "Overview of our approach",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 1,
          title: "Understanding the Market",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "Location",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
        {
          key: 3,
          title: "Scale",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
    {
      title: "HVS",
      key: 2,
      item: [
        {
          key: 1,
          title: "Understanding the Market",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "HVS - Feasibility Study and Valuation Report",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/hvs/2012-01-27+HVS-Marriott+Feasibility+STudy+and+Valuation+Report.pdf"
                }
              />
            </div>
          ),
        },
        {
          key: 3,
          title: "HVS - Proposal",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/hvs/HVS+-+Feasibility+Proposal+-+Weligama+Bay+(May+2011).pdf"
                }
              />
            </div>
          ),
        },
        {
          key: 3,
          title: "HVS - Cost Estimate Guide 2021 (USA)",
          description: (
            <div>
              <p>
                While the cost of development varies significantly in Asia, the
                guide provides insight into the various considerations.
              </p>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/hvs/Hotel+Costing+Estimate+Guide+-+2012+HVS.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
    {
      title: "Marriott International",
      key: 3,
      item: [
        {
          key: 1,
          title: "Understanding the Market",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "HVS",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
    {
      title: "Spectra Hospitality",
      key: 4,
      item: [
        {
          key: 1,
          title: "The Initial Concept",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "HVS",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
  ];

  return <ArticleList articleData={articleData} />;
};
