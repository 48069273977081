import { Button, Popover } from "antd";
import { HeartOutlined, HeartTwoTone } from "@ant-design/icons";

export const SupportUs = () => {
  return (
    <Button
      style={{ textDecoration: "none" }}
      rel={"noreferrer"}
      target="_blank"
      href={"https://buy.stripe.com/28o3dz7Gua0S43m8wW"}
      size="small"
      shape="round"
    >
      <small>{"Support Us"}</small>
      <HeartTwoTone
        twoToneColor="salmon"
        style={{ verticalAlign: "baseline", margin: "2px", fontSize: "18px" }}
      />
      <small>{"techForGood.lk"}</small>
    </Button>
  );
};

export const SupportUsArticle = () => {
  return (
    <Popover content={"show us some love!"} title="">
      <Button
        style={{ textDecoration: "none" }}
        rel={"noreferrer"}
        target="_blank"
        href={"https://buy.stripe.com/28o3dz7Gua0S43m8wW"}
        size="small"
      >
        <HeartOutlined style={{ verticalAlign: "middle", margin: "2px" }} />
        <small>{"Support Us"}</small>
      </Button>
    </Popover>
  );
};
