import { Image } from "antd";
import { ViewPdfUpload } from "../../utils/viewPdfUpload";
import { ArticleList } from "../article/articleList";

export const StartHere = () => {
  // const template = {
  //   key: 1,
  //   title: "Article here",
  //   description: (
  //     <div>
  //       <p></p>
  //       {/* <ViewPdfUpload
  //         pdfURL={
  //           "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
  //         }
  //       /> */}
  //     </div>
  //   ),
  // };

  const articleData = [
    {
      title: "Acknowledgement",
      key: 2,
      item: [
        {
          key: 1,
          title: "Thank You!",
          description: (
            <div style={{ textAlign: "center" }}>
              <p>
                {
                  "Thank you to my father for providing me and my sister to the opportunity to undertake such a project"
                }
              </p>
              <p>{"The biggest thing we take away from this is confidence!"}</p>
              {/* <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              /> */}
              <p>
                {
                  "This is my effort to contribute back to the world wide web from such I have learned and take so much from."
                }
              </p>
            </div>
          ),
        },
        {
          key: 2,
          title: "What is techForGood.lk?",
          description: (
            <div>
              <p>
                {
                  "Since building the Marriott, I have focused my effort on teaching and transition people from low income backgrounds into a career in tech."
                }
              </p>
              <p>
                {
                  "techForGood.lk is an initative to transition people into a career in technology. In partnership with the foundation of goodness we teach basic html , css, javascript and computer logic. Our aim is to provide people with the tool and appreciation for computer logic."
                }
              </p>
              <p>
                {
                  "This web app is built and managed by graduates of the course and encourages them to build their own commerically viable ventures"
                }
              </p>
              <div style={{ textAlign: "center" }}>
                <Image
                  width={300}
                  src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-tech-for-good/fog-class.JPG"
                />
                <p>{"Introduction to Coding"}</p>
                <Image
                  width={300}
                  src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-tech-for-good/fog-class-01.JPG"
                />
                <p>
                  <a
                    href="https://techforgood.lk/mission"
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {"techForgood.lk"}
                  </a>
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Start Here",
      key: 1,
      item: [
        {
          key: 1,
          title: "Background...",
          description: (
            <div>
              <h1 style={{ fontSize: "1.2rem" }}>{"Background..."}</h1>
              <p>
                {
                  "In 2012, we set out on a bold endeavor to construct a hotel. At the beginning, it was not affiliated with Marriott and we never imagined it would become a 15-story resort with 200 rooms. Our initial plan was to create a quaint, small boutique hotel. This website serves as a guide to our journey, delving into the ideas, plans, obstacles, and reasoning behind our decision-making process. "
                }
              </p>
              <div style={{ textAlign: "center" }}>
                <Image
                  width={300}
                  src="https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/3d-model/2011-10-03+Foam+board+%2B+thread+bar+Model+of+Hotel+Concept+034.jpg"
                />
                <p>{"2012 - Built a model of the hotel in my living"}</p>
                <Image
                  width={300}
                  src="https://bmkltsly13vb.compat.objectstorage.ap-mumbai-1.oraclecloud.com/cdn.ft.lk/ftadmin/wp-content/uploads/2017/07/23195609/123123131.jpg"
                />
                <p>{"Completed in 2018"}</p>
              </div>
              <p>
                {
                  "As first time developers we did not fully grasp the impact of our decisions, a lot of the planning was done during construction resulting in numerous changes and delays."
                }
              </p>
              <p>
                {
                  "My hope is that the site can provide a overview of the different aspects of the development process related financing."
                }
              </p>
            </div>
          ),
        },

        {
          key: 2,
          title: "Navigating the Site",
          description: (
            <div>
              <p>
                {
                  "The site is structure into the various disciplines providing a clear seperation of concerns."
                }
              </p>
              <p>
                {
                  "The site has a strong emphasis of the planning and design stage, much of the project and its details can be understand at this stage providing for much better execution when you actually start constructing"
                }
              </p>
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/NpEaa2P7qZI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div style={{ textAlign: "center" }}>
                <p>{"Overview of the site"}</p>
              </div>
            </div>
          ),
        },
        {
          key: 3,
          title: "Article with PDF",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
  ];

  return <ArticleList articleData={articleData} />;
};
