import { Col, Divider, Row } from "antd";
import { SupportUs } from "../../utils/suppportUs";

export const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Row gutter={[18, 18]} style={{ margin: "20px", maxWidth: "1200px" }}>
        <Divider></Divider>
        <Col xs={24} sm={24} md={14}>
          <p style={{ fontWeight: "900" }}>marriott case stuty</p>
          <p style={{ fontSize: "14px" }}>
            {
              "the app aims to provide a comprehensive account of the concept, design, development and execution of a 200 key Marriott Resort and spa in "
            }
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://www.google.com/maps/place/Weligama+Bay+Marriott+Resort+%26+Spa/@5.9906647,80.4008187,12.78z/data=!4m11!3m10!1s0x3ae11545eda17fd9:0xe4d7ca849dbecbbe!5m4!1s2023-05-08!2i3!4m1!1i2!8m2!3d5.9730503!4d80.4394055!16s%2Fg%2F11byp5wcz4?coh=164777&entry=tt"
            >
              {"Weligama Bay, Sri Lanka"}
            </a>
          </p>
          <p style={{ margin: "0px" }}> {"Acknowledgement"}</p>
          <p>
            <small>
              {
                "a special thanks to my father for providing me and my sister the opportunity to undertake such a project."
              }
            </small>
          </p>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Links</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                BOI
              </a>
            </li>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://eastwest.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                SLTDA
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={12} md={5}>
          <p style={{ fontWeight: "700" }}>Community</p>
          <ul style={{ listStyleType: "none", padding: "0px" }}>
            <li>
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={
                  "https://www.linkedin.com/company/east-west-properties-plc/"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </Col>
        <Row>
          <Col span={24} style={{ textAlign: "center" }}>
            <SupportUs />
          </Col>
        </Row>
        <Divider></Divider>
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontSize: "12px" }}>
              {"built by "}
              <a
                style={{
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "salmon",
                }}
                href={"https://techforgood.lk"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {"techForGood"}
              </a>
              &copy;{" 2023"}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
