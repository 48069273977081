import { ViewPdfUpload } from "../../utils/viewPdfUpload";
import { ArticleList } from "../article/articleList";

export const Marriott = () => {
  // Important
  // To be used as a template for articles
  // copy the file and edit

  const articleData = [
    {
      title: "Marriott International",
      key: 1,
      item: [
        {
          key: 1,
          title: "About Marriott",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "How to get in touch",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 3,
          title: "How did we choose Marriott?",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 3,
          title: "Why have Hotel management Company?",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
    {
      title: "Management Agreement",
      key: 1,
      item: [
        {
          key: 1,
          title: "About Marriott",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 2,
          title: "HVS - Proposal - Management Contract Negotiation",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://customer-apps-techhq.s3.eu-west-2.amazonaws.com/app-marriott-case-study/hvs/HVS-Proposal+Management+Contract+Negotiation+-+Weligama+Bay+-+August+2011.pdf"
                }
              />
            </div>
          ),
        },
        {
          key: 1,
          title: "Apara Hospitality",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
      ],
    },
  ];

  return <ArticleList articleData={articleData} />;
};
