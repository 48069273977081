export const directoryFeasibility = [
  {
    title: "Marriott -  Development Planning and Feasibility",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+Cjxwb2x5Z29uIGZpbGw9IiNiNzFjMWMiIHBvaW50cz0iMTEsOSAxOSw2IDM0LDQwIDI1LDQwIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iI2I3MWMxYyIgcG9pbnRzPSIyNS4zOTcsOS44NDIgMzIuOTc4LDYuMjIzIDQ4LDQwIDM5LDQwIj48L3BvbHlnb24+PHJlY3Qgd2lkdGg9IjExLjAxMSIgaGVpZ2h0PSIyIiB4PSIyNC43NTgiIHk9IjI0LjIyNiIgZmlsbD0iI2I3MWMxYyIgdHJhbnNmb3JtPSJyb3RhdGUoLTY1LjYgMzAuMjYzIDI1LjIyNSkiPjwvcmVjdD48cGF0aCBmaWxsPSIjYjcxYzFjIiBkPSJNMCw0MmwzLjk3My03LjU1NGMwLDAsMS45MjIsMS4wNDQsNC45ODktMC42ODVjNC41OTgtMi41OTIsOC43NTUtMTEuNzg4LDguNzU1LTExLjc4OGwxLjE3NCwxLjY2MwljMCwwLTUuNTQzLDE5LjM3LTE0LjgwNCwxOS4zN0MwLjc2MSw0My4wMDUsMCw0MiwwLDQyeiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    description: "Reasearch & Feasibility Study",
  },
  {
    title: "HVS",
    thumbnail: (
      <img
        width={"10px"}
        alt="svgImg"
        src="https://res.cloudinary.com/climb/image/upload/c_fill,f_auto,h_250,w_250,q_95/v1524450621/tcfdlidezjb1g14e6eux"
      />
    ),
    description: "Reasearch & Feasibility Study",
  },
  {
    title: "Spectra Hospitality",
    thumbnail: (
      <img
        alt="svgImg"
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhCmk_McTUa9Q8Y-Ogu-R0uNE8W5HtXT5WuYqN_yLW7w&s"
      />
    ),
    description: "Reasearch & Feasibility Study",
  },
  {
    title: "Alexander Wallace",
    thumbnail: (
      <img alt="svgImg" src={`https://robohash.org/placeholder.png`} />
    ),
    description: "Reasearch & Feasibility Study",
  },
];

export const directoryDesign = [
  {
    title: "Marriott Internationl",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+Cjxwb2x5Z29uIGZpbGw9IiNiNzFjMWMiIHBvaW50cz0iMTEsOSAxOSw2IDM0LDQwIDI1LDQwIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iI2I3MWMxYyIgcG9pbnRzPSIyNS4zOTcsOS44NDIgMzIuOTc4LDYuMjIzIDQ4LDQwIDM5LDQwIj48L3BvbHlnb24+PHJlY3Qgd2lkdGg9IjExLjAxMSIgaGVpZ2h0PSIyIiB4PSIyNC43NTgiIHk9IjI0LjIyNiIgZmlsbD0iI2I3MWMxYyIgdHJhbnNmb3JtPSJyb3RhdGUoLTY1LjYgMzAuMjYzIDI1LjIyNSkiPjwvcmVjdD48cGF0aCBmaWxsPSIjYjcxYzFjIiBkPSJNMCw0MmwzLjk3My03LjU1NGMwLDAsMS45MjIsMS4wNDQsNC45ODktMC42ODVjNC41OTgtMi41OTIsOC43NTUtMTEuNzg4LDguNzU1LTExLjc4OGwxLjE3NCwxLjY2MwljMCwwLTUuNTQzLDE5LjM3LTE0LjgwNCwxOS4zN0MwLjc2MSw0My4wMDUsMCw0MiwwLDQyeiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    description: "Developement",
    overview: (
      <div>
        <p>
          {
            "Marriott International is the world's largest hospitality brand with over 8000 properties and 173 Million Loyalty Card Memebers and Largest International Brand in India."
          }
        </p>
        <p>{"Key People in the development team:"}</p>
        <ul>
          <li>
            {"Kiran Andicot - Regional Vice President South Asia "}
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.linkedin.com/in/andicot1234/"
            >
              {"Linkedin"}
            </a>
            {" | "}
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="mailto:kiran.andicot@marriott.com"
            >
              {"Email"}
            </a>
          </li>
          <li>
            {"Juhi Roj - Director "}
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="https://www.linkedin.com/in/juhi-roy-58446014/"
            >
              {"Linkedin"}
            </a>
            {" | "}
            <a
              target={"_blank"}
              rel={"noreferrer"}
              href="mailto:juhi.roy@marriott.com"
            >
              {"Email"}
            </a>
          </li>
        </ul>
        <p>
          {
            "Our experience with Marriott as first time hotel developers was excellent. Their experience and input during the planning and design phase really helped make the property the commercial success it is today "
          }
        </p>
        <p>{"Reasons for choosing Marriot:"}</p>
        <ul>
          <li>
            {"Most responsing during our initial phase of courting an operator"}
          </li>
          <li>{"Extensive & Knowledgable technical development team"}</li>
          <li>
            {
              "Bonvoy - Marriott's Loyalty card program (30% of our guest come via their reards program)"
            }
          </li>
          <li>
            {"Largest operator in India - a key feeder market for Sri Lanka"}
          </li>
          <li>{"Really good chemistry with the team!"}</li>
        </ul>
        <strong>
          <p>
            {
              "Reach out to Kiran if you have project in mind - this should be your first step!"
            }
          </p>
        </strong>

        <p>
          <i>"Land chasing the hotel or hotel chasing the land?"</i>
        </p>
      </div>
    ),
  },
  {
    title: "Marriott Technical Services",
    thumbnail: (
      <img
        alt="svgImg"
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+Cjxwb2x5Z29uIGZpbGw9IiNiNzFjMWMiIHBvaW50cz0iMTEsOSAxOSw2IDM0LDQwIDI1LDQwIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iI2I3MWMxYyIgcG9pbnRzPSIyNS4zOTcsOS44NDIgMzIuOTc4LDYuMjIzIDQ4LDQwIDM5LDQwIj48L3BvbHlnb24+PHJlY3Qgd2lkdGg9IjExLjAxMSIgaGVpZ2h0PSIyIiB4PSIyNC43NTgiIHk9IjI0LjIyNiIgZmlsbD0iI2I3MWMxYyIgdHJhbnNmb3JtPSJyb3RhdGUoLTY1LjYgMzAuMjYzIDI1LjIyNSkiPjwvcmVjdD48cGF0aCBmaWxsPSIjYjcxYzFjIiBkPSJNMCw0MmwzLjk3My03LjU1NGMwLDAsMS45MjIsMS4wNDQsNC45ODktMC42ODVjNC41OTgtMi41OTIsOC43NTUtMTEuNzg4LDguNzU1LTExLjc4OGwxLjE3NCwxLjY2MwljMCwwLTUuNTQzLDE5LjM3LTE0LjgwNCwxOS4zN0MwLjc2MSw0My4wMDUsMCw0MiwwLDQyeiI+PC9wYXRoPgo8L3N2Zz4="
      />
    ),
    description: "Technical Service",
    overview: "hello",
    subDirectory: "interior design",
  },
  {
    title: "Wilson Associates",
    description: "Lead - Interior Designer",
    subDirectory: "interior design",
  },
  {
    title: "Belt Collions",
    description: "Lead - Lanscaping",
  },
  {
    title: "ACS Intergrated",
    description: "Local - Architect",
    subDirectory: "interior design",
  },
  {
    title: "Studio906",
    description: "Concept - Architect",
  },
  {
    title: "Photonics",
    description: "Light Consultant",
  },
  {
    title: "Ligh@Works",
    description: "Light Consultant",
  },
  {
    title: "HPG Consultants",
    description: "BOH Consultants",
  },
  {
    title: "AXIS",
    description: "Facade Consultants",
  },
  {
    title: "NCD",
    description: "Stuctural Engineers",
    subDirectory: "structural",
  },
  {
    title: "K2 Consultants",
    description: "Electrical & ELV Consultants",
  },
  {
    title: "KE Consultants",
    description: "HVAC Engineers",
  },
  {
    title: "Ultracon",
    description: "Post Tension Engineers",
    subDirectory: "structural",
  },
  {
    title: "C-Engery",
    description: "LEAD Consultants",
  },
  {
    title: "Apara Hospitality",
    description: "Management Agreement Adviser",
  },
];

export const directoryFinance = [
  {
    title: "Hattion National Bank",
    description: "Debt Financing",
    thumbnail: (
      <img alt={"HNB Logo"} src={"https://www.hnb.net/images/hnb-logo.jpg"} />
    ),
  },
  {
    title: "Bank of Ceylon",
    description: "Debt Financing",
    thumbnail: (
      <img
        alt={"BOC Logo"}
        src={
          "https://boc.lk/catalog/view/theme/default/images/new/boc-logo.jpg"
        }
      />
    ),
  },
  {
    title: "Lanka Securities",
    description: "Private Equity",
    thumbnail: (
      <img
        alt={"LSL logo"}
        src={
          "https://cdn.cse.lk/cms-internal/logo/4BWVBWRuBLhVJU43_28Aug2022090133GMT_1661677293006.jpeg"
        }
      />
    ),
  },
  {
    title: "NDB Investment Bank",
    description: "Private Equity",
    thumbnail: (
      <img
        alt={"NDB logo"}
        src={
          "https://scontent.ftlv19-1.fna.fbcdn.net/v/t1.6435-9/79789438_1820112701453620_4938467617301069824_n.png?_nc_cat=108&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=ihwTqgba9NwAX-eupxr&_nc_ht=scontent.ftlv19-1.fna&oh=00_AfCVrtsRRhAYvZthqXkf8FrW5FyhF7JqZPITCixxzeh5tA&oe=6412B451"
        }
      />
    ),
  },
  {
    title: "Jones Lang Lasalle",
    description: "Advisors & Property Managers",
    thumbnail: (
      <img
        alt={"JLL Logo"}
        src={
          "https://download.logo.wine/logo/JLL_(company)/JLL_(company)-Logo.wine.png"
        }
      />
    ),
  },
  {
    title: "Sri Lanka Insurnce Corporation",
    description: "Insurance",
    thumbnail: (
      <img
        alt={"SLIC Logo"}
        src={
          "https://media.licdn.com/dms/image/C510BAQE44IUbE9Lgkg/company-logo_200_200/0/1557309479276?e=1684368000&v=beta&t=3VyXqzPq9rc0-XBb0P4N8VejlbRCDhtex8ME4QfP4QY"
        }
      />
    ),
  },
  {
    title: "Employee Provident Fund Sri Lanka",
    description: "Investor",
    thumbnail: (
      <img
        alt={"EPF logo"}
        src={
          "https://www.dailynews.lk/sites/default/files/news/2018/04/01/z_p01-Laws.jpg"
        }
      />
    ),
  },
];

export const directoryContractors = [
  { title: "East West Engineering", description: "Main Contractor" },
  { title: "Fentons", description: "Electrical & ELV" },
  { title: "Shin Nippon", description: "Plumbing" },
  { title: "Access Engineering", description: "HVAC" },
  { title: "Allied Metals", description: "Kitchens" },
  {
    title: "VK Enterprises",
    description: "Ceiling & Walls",
    overview:
      "Advance Payment For Ceiling Works Ballroom Pre Function + Ceiling  Works - Guest Rooms ",
  },
  { title: "Access Project Management", description: "Project Management" },
  { title: "East West Ready Mix", description: "Concrete Supplier" },
  {
    title: "Stylish Window Lanka",
    description: "Aluminimum Fabrication - ALUK",
    overview:
      "Fixing of Door and Window with 95mm Fixed Frame with Tempered glass and Accessories",
    subDirectory: "doors & windows",
  },
  {
    title: "Alpha Thermal System",
    description: "Heat Pumps",
    overview: "Supply of heat pumps",
    subDirectory: "mep",
  },
  {
    title: "R.M Perera (Pvt) Ltd.",
    description: "Guestroom Furniture",
    overview: "Supply and Installation of Great Room Furniture",
    subDirectory: "furniture",
  },
  {
    title: "Foster & Reed Co",
    description: "Swimming Pool",
    overview: "Swimming pool Works",
    subDirectory: "mep",
  },
  {
    title: "Ecology System",
    description: "Sewerage treatment Plant",
    overview: "Sewerage treatment Plant Work",
    subDirectory: "mep",
  },
  {
    title: "JVSN Trading & Engineering Company ",
    description: "Shower Cubicals - Guestrooms",
    overview:
      "Supply & Installation of 10mm Clear Tempered Glass shower cubical for all guest rooms L shape fixed glass for twin room and Presidential suit + Supply and installation of 12 mm Clear Tempered Glass Balcony BalastradeFor Guest Room. ",
    subDirectory: "glass",
  },
  {
    title: "Excel Holding",
    description: "Stone & Marble",
    overview: "Supply and installation of Marble for Gest room",
    subDirectory: "stones, marble & granite",
  },
  {
    title: "Durra Building System ",
    description: "Fire Door",
    overview: "Fire Door",
    subDirectory: "doors & windows",
  },
  {
    title: "Globe Mirror Industries",
    description: "Glass Work",
    overview: "Glass Work Podium Level",
    subDirectory: "glass",
  },
  {
    title: "Specialty Construction Chemicals",
    description: "Paints",
    overview: "Supply and Application for External Painting ",
  },
  {
    title: "IQ System ",
    description: "Guestroom Energy Management",
    overview: "INNCOM Solution",
    subDirectory: "mep",
  },
  {
    title: "Airow Solution",
    description: "water proofing",
    overview: "Water Proofing Work of Water Tanks",
  },
  {
    title: "Finco Engineering",
    description: "waterproofing",
    overview:
      "Supply & water Proof Roof Top, Lift well, Water Tank, Slab & Swimming Pool.",
  },
  {
    title: "Eshwara Trans Planters",
    description: "Lanscaping",
    overview: "Landscaping Contractor",
  },
  {
    title: "Globe Mirror Industries",
    description: "Glass - ELevator Facade",
    overview:
      "Supply and installation of 12 mm ST136 tempered GLASS FIXED PANEL FOR LIFT WALL ",
    subDirectory: "glass",
  },
  {
    title: "Fober Marshall.",
    description: "Steam Boilers",
    overview: "Supply and Installation of Steam piping System.",
    subDirectory: "mep",
  },
  {
    title: "Specialty Construction Chemicals",
    description: "water proofing",
    overview:
      "Application of water proofing system for all wet areas at Marriot Hotel and Spa",
  },
  {
    title: "IDEA HUB",
    description: "Signage",
    overview:
      "Suppliy @ Installation of Cfacade Signage & Road Side Front Signage",
  },
  {
    title: "Trade Promoters",
    description: "Generators",
    overview: "Supply and Installationof 1500kva Generators,",
    subDirectory: "mep",
  },
  {
    title: "Samren Holdings ",
    description: "paving blocks",
    overview:
      "for interlock pavings for Ramp and landscape area of main building",
  },
  {
    title: "Asiri Garden Services ",
    description: "lanscaping",
    overview: "lanscaping",
  },
  {
    title: "Safe Gas Systems",
    description: "Gas System",
    overview: "LP Gas System",
    subDirectory: "mep",
  },
  {
    title: "I-Panel Ceiling System",
    description: "celings",
    overview: "Supply & Installation BOH-AREA",
    subDirectory: "ceiling & walls",
  },
  {
    title: "Granite World Company",
    description: "Granite & Marble",
    overview:
      "Supplying and Installation of Vanity Counter & Marble Seatings, + Loobby Column Cladding Works",
    subDirectory: "stones, marble & granite",
  },
  {
    title: "SCAN Engineering ",
    description: "Elevators",
    overview: "",
    subDirectory: "mep",
  },
  {
    title: "Star Granite",
    description: "Stone",
    overview: "Star Granite-For Boundry Wall",
    subDirectory: "stones, marble & granite",
  },
  {
    title: "Sirocco Air Technologies",
    description: "Scrubbers",
    overview: "Scrubber Installation",
    subDirectory: "mep",
  },
  {
    title: "Geotech",
    description: "Soil Investigation",
    overview: "Soil testing services",
  },
  {
    title: "Dialog Enterprise",
    description: "Mobile Solutions",
    overview:
      "Supply, installation, and maintenance of In-Building GSM and 3G solution ",
    subDirectory: "mep",
  },
  {
    title: "S.Y.M.Engineering Enterprises",
    description: "steam pipes",
    overview:
      "Connecting pipe lines from wall main line to machine - Laundry Department",
    subDirectory: "mep",
  },
  {
    title: "Alumid Enterprises",
    description: "Aluminium Door & Window",
    overview: "Aluminium Door & Window",
    subDirectory: "doors & windows",
  },
  {
    title: "Buddhi Fabrications",
    description: "Ceilings",
    overview:
      "Lobby Level  meeting roomCeiling Work/Main Loby Level Area Ceiling Work/Gym & Lounge/Waiting Area/All Day Dinning",
    subDirectory: "ceiling & walls",
  },
  {
    title: "Woody Wood Creators",
    description: "Doors",
    overview: "Wooden Door Work",
    subDirectory: "doors & windows",
  },
  {
    title: "Woodstyle",
    description: "Doors",
    overview: "Timber Doors",
    subDirectory: "doors & windows",
  },
  {
    title: "Sausiri Property Developers",
    description: "paving & Granite",
    overview:
      "200mm Thick Front area as Per Attached /Typical Granite-(Steps-1)/Yellow Decorative Stone-Typical Paving-2",
    subDirectory: "stones, marble & granite",
  },
  {
    title: "Lakmali Furniteck",
    description: "ALL Day dinning",
    overview: "ADD Resturent",
    subDirectory: "furniture",
  },
  {
    title: "Revolve Holding",
    description: "OUTDOOR DECKING",
    overview: "OUTDOOR DECKING",
  },
  {
    title: "Chands Interior & Constructions",
    description: "Kids Club Ground Floor & Lift Lobby",
    overview: "Kids Club Ground Floor & Lift Lobby",
    subDirectory: "ceiling & walls",
  },
  {
    title: "Nikasala Interior & Contractor",
    description: "Cladding Walls",
    overview:
      "Timber Cladding Work-ADD & Ground Floor/Timber Cladding Work-Main Lobby/Timber Ceiling Big Fish Area",
    subDirectory: "ceiling & walls",
  },
  {
    title: "HDS & Constructions",
    description: "Ball Room Wall & Column Cladding",
    overview: "Ball Room Wall & Column Cladding",
    subDirectory: "ceiling & walls",
  },
  {
    title: "M.R. Cooray Saw Mills",
    description: "GYM & Spa area",
    overview: "GYM & Spa area",
  },
  {
    title: "Ceylinco Industries ",
    description: "",
    overview:
      "Supply Of Fix Furniture For Big Fish Resturent & Timber Screen Ground Floor Circulation and Lift Lobby Area,Counter For Add & Big Fish ",
    subDirectory: "furniture",
  },
];
export const directorySupplier = [
  { title: "Falcon", description: "Guestroom + Public Space Furnture" },
  { title: "Don Carolis", description: "Guestroom + Public Space Furnture" },
  { title: "Alpha Furniture", description: "BOH Furnture" },
  { title: "John Keelles", description: "TV & Displays" },
  { title: "Fit & Spa Solutions", description: "Gym Equipment" },
  { title: "Noritake", description: "Crockery" },
  { title: "Trade Promoters", description: "Generator" },
];

export const directoryGovernement = [
  { title: "Sri Lanka Tourism", description: "SLTDA" },
  { title: "Board of Investment", description: "Foriegn Investment" },
  { title: "Urdan Development Authority", description: "UDA" },
  { title: "Coast Conservation Department", description: "CCD" },
];
