export const mainPartnerData = [
  {
    key: 1,
    title: "Marriott International",
    url: "https://www.hotel-development.marriott.com/",
    color: "cyan",
    thumbnail:
      "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+Cjxwb2x5Z29uIGZpbGw9IiNiNzFjMWMiIHBvaW50cz0iMTEsOSAxOSw2IDM0LDQwIDI1LDQwIj48L3BvbHlnb24+PHBvbHlnb24gZmlsbD0iI2I3MWMxYyIgcG9pbnRzPSIyNS4zOTcsOS44NDIgMzIuOTc4LDYuMjIzIDQ4LDQwIDM5LDQwIj48L3BvbHlnb24+PHJlY3Qgd2lkdGg9IjExLjAxMSIgaGVpZ2h0PSIyIiB4PSIyNC43NTgiIHk9IjI0LjIyNiIgZmlsbD0iI2I3MWMxYyIgdHJhbnNmb3JtPSJyb3RhdGUoLTY1LjYgMzAuMjYzIDI1LjIyNSkiPjwvcmVjdD48cGF0aCBmaWxsPSIjYjcxYzFjIiBkPSJNMCw0MmwzLjk3My03LjU1NGMwLDAsMS45MjIsMS4wNDQsNC45ODktMC42ODVjNC41OTgtMi41OTIsOC43NTUtMTEuNzg4LDguNzU1LTExLjc4OGwxLjE3NCwxLjY2MwljMCwwLTUuNTQzLDE5LjM3LTE0LjgwNCwxOS4zN0MwLjc2MSw0My4wMDUsMCw0MiwwLDQyeiI+PC9wYXRoPgo8L3N2Zz4=",
  },
  {
    key: 2,
    title: "SLTDA",
    url: "https://www.sltda.gov.lk/en",
    color: "cyan",
    thumbnail: "https://www.sltda.gov.lk/images/sltda-logo.png",
  },
  {
    key: 3,
    title: "Board of Investment",
    url: "https://investsrilanka.com/",
    color: "cyan",
    thumbnail:
      "https://investsrilanka.com/wp-content/uploads/2020/11/BOI-LOGO--150x150.jpg",
  },
  {
    key: 4,
    title: "Urban Development Authority",
    url: "https://www.uda.gov.lk/",
    color: "cyan",
    thumbnail: "https://applications.uda.lk/images/logo/logo.png",
  },
];

export const designPartnerData = [
  {
    key: 1,
    title: "Wilson Associates",
    url: "https://www.wilson-associates.net/",
    color: "cyan",
    thumbnail: "https://robohash.org/wilsonAssociates",
  },
  {
    key: 2,
    title: "ACS Intergrated",
    url: "https://acsintegrated.com/",
    color: "cyan",
    thumbnail: "https://robohash.org/acs",
  },
  {
    key: 3,
    title: "Belt Collins",
    url: "https://bchdesign.com/",
    color: "cyan",
    thumbnail:
      "https://media.licdn.com/dms/image/C560BAQH5Ubynqb0QlA/company-logo_200_200/0/1617306904557?e=1684368000&v=beta&t=pQW03SqdCH_GpUN1A1BuS_joJiR6PEqHZJ7VfQkX_bU",
  },
  {
    key: 4,
    title: "Studio906",
    url: "https://www.studio906architects.com/home",
    color: "cyan",
    thumbnail: "https://robohash.org/studio906",
  },
];
export const servicesPartnerData = [
  {
    key: 1,
    title: "Hayleys-Fentons",
    url: "https://www.hayleysfentons.com/",
    color: "cyan",
    thumbnail:
      "https://www.hayleysfentons.com/wp-content/uploads/2022/11/Untitled-design-2022-08-30T214522.355-1.png.webp",
  },
];
export const financePartnerData = [
  {
    key: 1,
    title: "Lanka Securities",
    url: "http://lsl.lk/",
    color: "cyan",
    thumbnail:
      "https://cdn.cse.lk/cms-internal/logo/4BWVBWRuBLhVJU43_28Aug2022090133GMT_1661677293006.jpeg",
  },
  {
    key: 2,
    title: "Bank of Ceylon",
    url: "https://boc.lk/",
    color: "cyan",
    thumbnail:
      "https://boc.lk/catalog/view/theme/default/images/new/boc-logo.jpg",
  },
  {
    key: 3,
    title: "Hatton National Bank",
    url: "https://www.hnb.net/",
    color: "cyan",
    thumbnail: "https://www.hnb.net/images/hnb-logo.jpg",
  },
  {
    key: 4,
    title: "JLL",
    url: "https://www.jll.com.lk/",
    color: "cyan",
    thumbnail:
      "https://download.logo.wine/logo/JLL_(company)/JLL_(company)-Logo.wine.png",
  },
];
