import { Col, Row, Table } from "antd";

export const Budget = () => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumSignificantDigits: 3,
  });
  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      width: "45px",
    },
    {
      title: "Catagory",
      dataIndex: "catagory",
      key: "catagory",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => {
        return formatter.format(amount);
      },
    },
  ];

  const expandedRowRender = (record) => {
    console.log(record.breakdown);
    const columns = [
      {
        title: "Key",
        dataIndex: "key",
        key: "key",
        width: "50px",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (amount) => {
          return formatter.format(amount);
        },
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={record.breakdown}
        pagination={false}
      />
    );
  };

  const budgetData = [
    {
      key: 1,
      catagory: "Civil Works",
      description: "All the civil works",
      amount: 4794676,
      breakdown: [
        {
          key: 1.1,
          catagory: "Civil Works",
          description:
            "All Concrete Works (sub-structure, Supper Structure ) of main building + Floor screed bedding + Guard House + other Mis. Concrete works + Land scape concreting + Kerbs + Water Features",
          amount: 4052752,
        },
        {
          key: 1.2,
          catagory: "Civil Works",
          description: "Waterproofing -For bath room and Toilet area",
          amount: 109071,
        },
        {
          key: 1.3,
          catagory: "Civil Works",
          description:
            "Waterproofing -For Roof top, Water sumps, Lift well, external façade",
          amount: 31656,
        },
        {
          key: 1.4,
          catagory: "Civil Works",
          description: "Masonry (hollow blocks) & Plaster",
          amount: 179943,
        },
        {
          key: 1.5,
          catagory: "Civil Works",
          description: "Masonry (Plain blocks) & Plaster",
          amount: 5000,
        },
        {
          key: 1.6,
          catagory: "Civil Works",
          description: "Construction of car Park inclding Interlock paving",
          amount: 107937,
        },
        {
          key: 1.7,
          catagory: "Civil Works",
          description:
            "Construction of Staff Accommodation - Including services and furnitures",
          amount: 440050,
        },
        {
          key: 1.8,
          catagory: "Civil Works",
          description:
            "Construction of Generator room, Transformer room,  boiler room, CEB  room, including wiring of buildings",
          amount: 105768,
        },
        {
          key: 1.9,
          catagory: "Civil Works",
          description:
            "Construction of Driver Quarters including services and furnitures",
          amount: 32198,
        },
        {
          key: 1.1,
          catagory: "Civil Works",
          description:
            "All Civil Works for Portable Water + Fire/Sprinkler Tanks and sumps  incl. Water Proofing",
          amount: 170992,
        },
        {
          key: 1.11,
          catagory: "Civil Works",
          description: "All Civil Works for STP incl. Water Proofing",
          amount: "Included in 1.10",
        },
        {
          key: 1.12,
          catagory: "Civil Works",
          description: "Micro Tunneling + Road Crossing",
          amount: 87034,
        },
      ],
    },
    {
      key: 2,
      catagory: "Site Establishment & Operational Cost",
      description: "Site Establishment & Operational Cost",
      amount: 1907473,
      breakdown: [
        {
          key: 2.1,
          catagory: "Site Establishment & Operational Cost",
          description: "Mock-up room",
          amount: 116400,
        },
        {
          key: 2.2,
          catagory: "Site Establishment & Operational Cost",
          description: "Security",
          amount: 110125,
        },
        {
          key: 2.3,
          catagory: "Site Establishment & Operational Cost",
          description: "Construction power",
          amount: 209450,
        },
        {
          key: 2.4,
          catagory: "Site Establishment & Operational Cost",
          description: "Site office running & travelling",
          amount: 634606,
        },
        {
          key: 2.5,
          catagory: "Site Establishment & Operational Cost",
          description: "Site Insurance",
          amount: 82122,
        },
        {
          key: 2.6,
          catagory: "Site Establishment & Operational Cost",
          description:
            "Site Machinery & Vehicle Handling Cost, Fuel & Maintenance  ",
          amount: 754770,
        },
      ],
    },
    {
      key: 3,
      catagory: "Façade Works",
      description: "Façade Works",
      amount: 920766,
      breakdown: [
        {
          key: 3.1,
          catagory: "Façade Works",
          description: "Glazing, Aluminum works + Glass work",
          amount: 363728,
        },
        {
          key: 3.2,
          catagory: "Façade Works",
          description: "Glass Railing  - Balcony Handrail system",
          amount: 128475,
        },
      ],
    },
    {
      key: 4,
      catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
      description: "Land Scaping (Hardscape, Softscape, Swimming pool)",
      amount: 742299,
      breakdown: [
        {
          key: 4.1,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description:
            "Swimming pools & water feature Civil Construction Works",
          amount: 167244,
        },
        {
          key: 4.2,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Softscape (Tress & plants)",
          amount: 133403,
        },
        {
          key: 4.3,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Man Hole & Man hole covers",
          amount: 23759,
        },
        {
          key: 4.4,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description:
            "Swimming pools & water features - Electro-Mechanical Works",
          amount: 146800,
        },
        {
          key: 4.5,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Concrete Paving Blocks",
          amount: 43722,
        },
        {
          key: 4.6,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Granite Paving All types",
          amount: 27602,
        },
        {
          key: 4.7,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Timber Hard Wood Laying - Land scape area",
          amount: 56613,
        },
        {
          key: 4.8,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Timber Hard Wood Laying - Roof top area",
          amount: 11076,
        },
        {
          key: 4.9,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Sand Stone / Sand tiles",
          amount: 4307,
        },
        {
          key: 4.1,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Ceramic Tiles ",
          amount: 7633,
        },
        {
          key: 4.11,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "LAVA  - Stone",
          amount: 42303,
        },
        {
          key: 4.12,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Laying of natural pebbles",
          amount: 3397,
        },
        {
          key: 4.13,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Loose granite chipped outs, ",
          amount: 0,
        },
        {
          key: 4.14,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Fixing granite chipped outs",
          amount: 0,
        },
        {
          key: 4.15,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Loose gravel and fixed gravel",
          amount: 0,
        },
        {
          key: 4.16,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Fixing  gravel",
          amount: 0,
        },
        {
          key: 4.17,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Artificial Turf",
          amount: 0,
        },
        {
          key: 4.18,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Land scape - Reception Art Works ",
          amount: 0,
        },
        {
          key: 4.19,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description:
            "Out Door Seating Terrace incl. all architectural features",
          amount: 9366,
        },
        {
          key: 4.2,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description:
            "Pool Bar structure and Kids pool Structures & canopies including architectural features",
          amount: 31834,
        },
        {
          key: 4.21,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description:
            "Guard Room All finishes including architectural features ",
          amount: 6272,
        },
        {
          key: 4.22,
          catagory: "Land Scaping (Hardscape, Softscape, Swimming pool)",
          description: "Main gate",
          amount: 10493,
        },
      ],
    },
    {
      key: 5,
      catagory: " Architectural Finishes - Guest Rooms, Corridors",
      description: "",
      amount: 5850501,
      breakdown: [
        {
          key: "5.1",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Sanitary & CP Fittings /Fixtures",
          amount: "337060.637333333",
        },
        {
          key: "5.2",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Tilling & Marble works - Supply only",
          amount: "688108.519533333",
        },
        {
          key: "5.3",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Fabric, Roller & Rug",
          amount: "191445.075933333",
        },
        {
          key: "5.4",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Loose furniture, Mattress & Divan",
          amount: "1907125.40033333",
        },
        {
          key: "5.5",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - All Doors and Accessories",
          amount: "739472.6704",
        },
        {
          key: "5.6",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Electrical & Lighting Fixtures",
          amount: "260339.769333333",
        },
        {
          key: "5.7",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Miscellaneous ID work",
          amount: "70171.5105333333",
        },
        {
          key: "5.8",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "GR - All Processing charges (LC, Pay order, Back draft , interest,etc)",
          amount: "46206.4019333333",
        },
        {
          key: "5.9",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "GR - Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "445628.642266667",
        },
        {
          key: "5.1",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "GR - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "141653.3652",
        },
        {
          key: "5.11",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "Ceiling works for all area (GR + Corrido area + Maid room)",
          amount: "494656.368133333",
        },
        {
          key: "5.12",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Wall Gypsum Borad for Guest rooms",
          amount: "19602.1099333333",
        },
        {
          key: "5.13",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "In side the Guest Room - Putty and Painting Application for Ceiling and Walls",
          amount: "92594.6626",
        },
        {
          key: "5.14",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Corrido Area - Flooring - Carpet ",
          amount: "152682.695533333",
        },
        {
          key: "5.15",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Corrido Area  Wall finishes  (Putty only) + Wall Paper",
          amount: "55927.2408666667",
        },
        {
          key: "5.16",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "( Maid Room, Service Lift, Stair Case Area ) - Flooring - Tiles",
          amount: "8419.5172",
        },
        {
          key: "5.17",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "( Maid Room, Service Lift, Stair Case Area ) - wall finishes - Putty and Paint Application",
          amount: "0",
        },
        {
          key: "5.18",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Passenger Lift Area - Flooring - Granite",
          amount: "0",
        },
        {
          key: "5.19",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Passenger Lift Area - Wall Finishes  - Wooden Panel",
          amount: "21122.4395333333",
        },
        {
          key: "5.20",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "(Maid room) - All Sanitary, Architectural, Electrical items",
          amount: "0",
        },
        {
          key: "5.21",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description:
            "GR - installation of Furnitures, and all architectural itesm",
          amount: "11573.1129333333",
        },
        {
          key: "5.22",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "INCOMM system",
          amount: "56812.1145333333",
        },
        {
          key: "5.23",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Shaft Doors",
          amount: "109898.862266667",
        },
        {
          key: "5.24",
          catagory: " Architectural Finishes - Guest Rooms, Corridors",
          description: "Supply  and Installation of Threshold ",
          amount: "0",
        },
      ],
    },
    {
      key: 6,
      catagory: "ARCHITECTURAL FINISHES ",
      description: "FOH Areas (Public Areas)",
      amount: 3081853,
      breakdown: [
        {
          key: "6.1",
          catagory: " Architectural Finishes - FOH Areas ",
          description: "FOH - HF (Flooring + skirting) - incl. laying & fixing",
          amount: "349398.168733333",
        },
        {
          key: "6.2",
          catagory: " Architectural Finishes - FOH Areas ",
          description: "FOH - HF ( Ceiling Works)",
          amount: "520390.061066667",
        },
        {
          key: "6.3",
          catagory: " Architectural Finishes - FOH Areas ",
          description:
            "FOH - HF  - Wall finish (Wall tile /Cladding/wooden wall)",
          amount: "422660.735866667",
        },
        {
          key: "6.4",
          catagory: " Architectural Finishes - FOH Areas ",
          description:
            "FOH - Sanitary wares fittings & fixtures (Public Toilet area)",
          amount: "8292.38133333333",
        },
        {
          key: "6.5",
          catagory: " Architectural Finishes - FOH Areas ",
          description:
            "FOH - All Furniture, Fabric, Door window internal, Decorative Electrical ligting fixtures, Mis. ID works, Carpet, Rugs, etc",
          amount: "1209237.17033333",
        },
        {
          key: "6.6",
          catagory: " Architectural Finishes - FOH Areas ",
          description: "FOH -  Chandelier",
          amount: "86509.5875333333",
        },
        {
          key: "6.7",
          catagory: " Architectural Finishes - FOH Areas ",
          description:
            "FOH - All Processing charges (LC, Pay order, Back draft , interest,etc)",
          amount: "26288.4714666667",
        },
        {
          key: "6.8",
          catagory: " Architectural Finishes - FOH Areas ",
          description: "FOH - Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "261230.710333333",
        },
        {
          key: "6.9",
          catagory: " Architectural Finishes - FOH Areas ",
          description:
            "FOH - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "41112.5376",
        },
        {
          key: "6.10",
          catagory: " Architectural Finishes - FOH Areas ",
          description: "project management Fee of all the FOH areas",
          amount: "156733.3334",
        },
      ],
    },
    {
      key: 7,
      catagory: "ARCHITECTURAL FINISHES - BOH area ",
      description: "ARCHITECTURAL FINISHES - BOH area ",
      amount: 474845,
      breakdown: [
        {
          key: "7.1",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - HF (Flooring + skirting) - Incl. laying & fixing",
          amount: "150554.216466667",
        },
        {
          key: "7.2",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - HF ( Ceiling Works + Partition work)",
          amount: "176686.805933333",
        },
        {
          key: "7.3",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - HF (Wall finishes)",
          amount: "35149.6156666667",
        },
        {
          key: "7.4",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - HF (Painting works)",
          amount: "625.987066666667",
        },
        {
          key: "7.5",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - Furniture for staff rooms",
          amount: "81699.3549333333",
        },
        {
          key: "7.6",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - Architectural Decorative items",
          amount: "19214.136",
        },
        {
          key: "7.7",
          catagory: " Architectural Finishes - BOH area ",
          description:
            "BOH - Sanitary wares fittings & fixtures (Staff toilets)",
          amount: "5791.79813333333",
        },
        {
          key: "7.71",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH-GI Doors",
          amount: "0",
        },
        {
          key: "7.8",
          catagory: " Architectural Finishes - BOH area ",
          description:
            "BOH - All Processing charges (LC, Pay order, Back draft , interest,etc)",
          amount: "2878.0686",
        },
        {
          key: "7.9",
          catagory: " Architectural Finishes - BOH area ",
          description: "BOH - Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "733.4382",
        },
        {
          key: "7.1",
          catagory: " Architectural Finishes - BOH area ",
          description:
            "BOH - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "1511.1096",
        },
      ],
    },
    {
      key: 8,
      catagory: "Signage",
      description: "Signage",
      amount: 75893,
      breakdown: [
        {
          key: "8.1",
          catagory: "Signage",
          description: "Room Number Signage",
          amount: "18002.2213333333",
        },
        {
          key: "8.2",
          catagory: "Signage",
          description: "Façade and  Road Front Signage (MARRIOT LOGO)",
          amount: "57890.7",
        },
        {
          key: "8.3",
          catagory: "Signage",
          description: "Way finding and other Wood signage",
          amount: "0",
        },
      ],
    },
    {
      key: 9,
      catagory: "HVAC Works",
      description: "HVAC Works",
      amount: 2260422,
      breakdown: [
        {
          key: "9.1",
          catagory: "HVAC Works",
          description:
            "HVAC - Supply, Installation and Testing and commissioning-Access",
          amount: "1828681.38866667",
        },
        {
          key: "9.2",
          catagory: "HVAC Works",
          description:
            "HVAC- All charges related to S/C works ( LC, Pay order, Back draft , interest,etc)",
          amount: "6865.62586666667",
        },
        {
          key: "9.3",
          catagory: "HVAC Works",
          description: "HVAC -Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "57898.871",
        },
        {
          key: "9.4",
          catagory: "HVAC Works",
          description:
            "HVAC - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "38788.4385333333",
        },
        {
          key: "9.5",
          catagory: "HVAC Works",
          description: "HVAC - Mis. /Minor items supplied by  the client ",
          amount: "27521.2470666667",
        },
        {
          key: "9.6",
          catagory: "HVAC Works",
          description: "HVAC - Variations and provisional sums - Provision",
          amount: "0",
        },
        {
          key: "9.7",
          catagory: "HVAC Works",
          description: "Chillers",
          amount: "246634.003133333",
        },
        {
          key: "9.8",
          catagory: "HVAC Works",
          description: "Fan Coil Units",
          amount: "54032.0293333333",
        },
      ],
    },
    {
      key: 10,
      catagory: "Electrical Works",
      description: "Electrical Works",
      amount: 3152793,
      breakdown: [
        {
          key: "10.1",
          catagory: "Electrical Works",
          description:
            "ELECTRICAL - Supply, Installation and Testing and commissioning-Fentons",
          amount: "2394386.29006667",
        },
        {
          key: "10.2",
          catagory: "Electrical Works",
          description:
            "ELECTRICAL - All charges related to S/C works ( LC, Pay order, Back draft , interest,etc)",
          amount: "11113.8818666667",
        },
        {
          key: "10.3",
          catagory: "Electrical Works",
          description:
            "ELECTRICAL -Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "104941.311",
        },
        {
          key: "10.4",
          catagory: "Electrical Works",
          description:
            "ELECTRICAL - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "24035.117",
        },
        {
          key: "10.5",
          catagory: "Electrical Works",
          description:
            "ELECTRICAL - Mis. /Minor items supplied by  the client ",
          amount: "57331.6298666667",
        },
        {
          key: "10.6",
          catagory: "Electrical Works",
          description:
            "Electrical - Variations and provisional sums - Provision",
          amount: "0",
        },
        {
          key: "10.7",
          catagory: "Electrical Works",
          description: "Supply and Installation of Generators",
          amount: "149576.7664",
        },
        {
          key: "10.8",
          catagory: "Electrical Works",
          description: "Vingcard System for Guest Rooms",
          amount: "5967.24486666667",
        },
        {
          key: "10.9",
          catagory: "Electrical Works",
          description: "Connection to CEB",
          amount: "0",
        },
        {
          key: "10.1",
          catagory: "Electrical Works",
          description: "Lighting Control System - Dimming Control system",
          amount: "41273.5217333333",
        },
        {
          key: "10.11",
          catagory: "Electrical Works",
          description: "Lighting - Public Area",
          amount: "146584.6558",
        },
        {
          key: "10.12",
          catagory: "Electrical Works",
          description: "Lighting - Façade",
          amount: "1299.92",
        },
        {
          key: "10.13",
          catagory: "Electrical Works",
          description:
            "Lighting - Landscape (Swimming pools, water features, out door, etc)",
          amount: "107762.504066667",
        },
        {
          key: "10.14",
          catagory: "Electrical Works",
          description: "Lighting - Guest room and Corrido",
          amount: "86849.4435333333",
        },
        {
          key: "10.15",
          catagory: "Electrical Works",
          description: "Lighting - BOH",
          amount: "21670.368",
        },
      ],
    },
    {
      key: 11,
      catagory: "Plumbing & Sanitary Systems",
      description: "Plumbing & Sanitary Systems",
      amount: 1494630,
      breakdown: [
        {
          key: "11.1",
          catagory: "Plumbing & Sanitary Systems",
          description:
            "PLB - Supply, Installation and Testing and commissioning- SNK",
          amount: "968507.282866667",
        },
        {
          key: "11.2",
          catagory: "Plumbing & Sanitary Systems",
          description:
            "PLB - All charges related to S/C works ( LC, Pay order, Back draft , interest,etc)",
          amount: "3095.67586666667",
        },
        {
          key: "11.3",
          catagory: "Plumbing & Sanitary Systems",
          description: "PLB -Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "14943.46",
        },
        {
          key: "11.4",
          catagory: "Plumbing & Sanitary Systems",
          description:
            "PLB - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "6753.48366666667",
        },
        {
          key: "11.5",
          catagory: "Plumbing & Sanitary Systems",
          description: "PLB - Mis./ Minor items supplied by  the client ",
          amount: "0",
        },
        {
          key: "11.6",
          catagory: "Plumbing & Sanitary Systems",
          description: "PLB - Variations and provisional sums - Provision",
          amount: "0",
        },
        {
          key: "11.7",
          catagory: "Plumbing & Sanitary Systems",
          description: "Sewerage treatment system",
          amount: "122927.726666667",
        },
        {
          key: "11.9",
          catagory: "Plumbing & Sanitary Systems",
          description: "Heat Pumps",
          amount: "179866.482066667",
        },
        {
          key: "11.1",
          catagory: "Plumbing & Sanitary Systems",
          description: "Boilers ",
          amount: "187536.096",
        },
        {
          key: "11.11",
          catagory: "Plumbing & Sanitary Systems",
          description: "Connection to NWSDB",
          amount: "11000",
        },
      ],
    },
    {
      key: 12,
      catagory: "Firefighting System",
      description: "Firefighting System",
      amount: 841573,
      breakdown: [
        {
          key: "12.1",
          catagory: "Fire Life & Safety System",
          description:
            "FF - Supply, Installation and Testing and commissioning (Fentons)",
          amount: "656871.377733333",
        },
        {
          key: "12.2",
          catagory: "Fire Life & Safety System",
          description:
            "FF - All charges related to S/C works ( LC, Pay order, Back draft , interest,etc)",
          amount: "2432.03986666667",
        },
        {
          key: "12.3",
          catagory: "Fire Life & Safety System",
          description: "FF - Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "26242.2933333333",
        },
        {
          key: "12.4",
          catagory: "Fire Life & Safety System",
          description:
            "FF - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "37603.6803333333",
        },
        {
          key: "12.5",
          catagory: "Fire Life & Safety System",
          description: "FF - Mis. /Minor items supplied by  the client ",
          amount: "470.906866666667",
        },
        {
          key: "12.6",
          catagory: "Fire Life & Safety System",
          description:
            "Electrical - Variations and provisional sums - Provision",
          amount: "0",
        },
        {
          key: "12.7",
          catagory: "Fire Life & Safety System",
          description: "Fire Doors Supplied by the Client ",
          amount: "117952.352466667",
        },
      ],
    },
    {
      key: 13,
      catagory: "Extra Low Voltage (ELV), IT, Software Systems",
      description: "Extra Low Voltage (ELV), IT, Software Systems",
      amount: 892609,
      breakdown: [
        {
          key: "13.1",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "BMS (Includes 13.02 - 13.11)",
          amount: "821802.606066667",
        },
        {
          key: "13.2",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Access Control System (ACS)",
          amount: "0",
        },
        {
          key: "13.3",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "CCTV",
          amount: "0",
        },
        {
          key: "13.4",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Fire Alarm System (FAS)",
          amount: "0",
        },
        {
          key: "13.5",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Public Address System (PAS)",
          amount: "0",
        },
        {
          key: "13.6",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Panic Alarm and Door Contact System",
          amount: "0",
        },
        {
          key: "13.7",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "MATV",
          amount: "0",
        },
        {
          key: "13.8",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Structured Cabling System ( Net Work Cabling)",
          amount: "0",
        },
        {
          key: "13.9",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Digital Signage",
          amount: "0",
        },
        {
          key: "13.1",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Audio- Visual System ",
          amount: "0",
        },
        {
          key: "13.11",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Property System",
          amount: "0",
        },
        {
          key: "13.12",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Guest Facing Technology",
          amount: "0",
        },
        {
          key: "13.13",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Voice Communication",
          amount: "0",
        },
        {
          key: "13.14",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Systemv & Building Infrastructure",
          amount: "28225.6",
        },
        {
          key: "13.15",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "Accomodation and meals for IT team of BALL PARK",
          amount: "0",
        },
        {
          key: "13.16",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description:
            "ELV - All charges related to S/C works ( LC, Pay order, Back draft , interest,etc)",
          amount: "5809.05493333333",
        },
        {
          key: "13.17",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description: "ELV - Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "25803.062",
        },
        {
          key: "13.18",
          catagory: "Extra Low Voltage (ELV), IT, Software Systems",
          description:
            "ELV - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "10968.8206666667",
        },
      ],
    },
    {
      key: 14,
      catagory: "Gas System",
      description: "Gas System",
      amount: 10202,
      breakdown: [
        {
          key: "14.1",
          catagory: "Gas System",
          description: "Gas Bank and piping works",
          amount: "10202.3106666667",
        },
      ],
    },
    {
      key: 15,
      catagory: "Vertical Transportation",
      description: "Vertical Transportation",
      amount: 339754,
      breakdown: [
        {
          key: "15.1",
          catagory: "Vertical Transportation",
          description:
            "Supply and Installation of All Passenger  and Service Elevators",
          amount: "339656.6056",
        },
        {
          key: "15.2",
          catagory: "Vertical Transportation",
          description: "Other mis. Items (doors, etc)",
          amount: "97.2",
        },
        {
          key: "15.3",
          catagory: "Vertical Transportation",
          description: "Vincard System for Elevator Access",
          amount: "0",
        },
      ],
    },
    {
      key: 16,
      catagory: "Facility Equipments",
      description: "Facility Equipments",
      amount: 1952459,
      breakdown: [
        {
          key: "16.1",
          catagory: "Facility Equipments",
          description: "Kitchen Equipments (Jysper)",
          amount: "1279505.1652",
        },
        {
          key: "16.2",
          catagory: "Facility Equipments",
          description: "Cold room (Waverly)",
          amount: "0",
        },
        {
          key: "16.3",
          catagory: "Facility Equipments",
          description: "Laundry ",
          amount: "274537.3392",
        },
        {
          key: "16.4",
          catagory: "Facility Equipments",
          description: "Gym Equipment",
          amount: "61406.8333333333",
        },
        {
          key: "16.5",
          catagory: "Facility Equipments",
          description: "Spa Equipment",
          amount: "22415.9429333333",
        },
        {
          key: "16.6",
          catagory: "Facility Equipments",
          description:
            "FM- All charges related to S/C works ( LC, Pay order, Back draft , interest,etc)",
          amount: "36505.4179333333",
        },
        {
          key: "16.7",
          catagory: "Facility Equipments",
          description: "FM -Custom Duty, CESS, PAL, NBT, VAT (if applicable)",
          amount: "218681.106666667",
        },
        {
          key: "16.8",
          catagory: "Facility Equipments",
          description:
            "FM - Custom clearance, Insurance, local transport, loading, unloading, demurrage",
          amount: "59406.7436666667",
        },
      ],
    },
    {
      key: 17,
      catagory: "Consultants",
      description: "Consultants",
      amount: 3826582,
      breakdown: [
        {
          key: "17.1",
          catagory: "Consultants",
          description: "PMC (Archetype)",
          amount: "250000",
        },
        {
          key: "17.2",
          catagory: "Consultants",
          description: "Local CM Staff",
          amount: "0",
        },
        {
          key: "17.3",
          catagory: "Consultants",
          description: "Architect 1 (Studio 906)",
          amount: "31160.2171641791",
        },
        {
          key: "17.4",
          catagory: "Consultants",
          description: "Architect 2 (ACOP)",
          amount: "18390",
        },
        {
          key: "17.5",
          catagory: "Consultants",
          description: "Nandika Samaranayaka-ACS",
          amount: "71780.303030303",
        },
        {
          key: "17.6",
          catagory: "Consultants",
          description: "BOH Interior Designer-Alexandra wallet",
          amount: "9099.48962686567",
        },
        {
          key: "17.7",
          catagory: "Consultants",
          description: "Facility Planner (HPG)",
          amount: "49000",
        },
        {
          key: "17.8",
          catagory: "Consultants",
          description: "Façade Consultant (AXIS)",
          amount: "24076.6908955224",
        },
        {
          key: "17.9",
          catagory: "Consultants",
          description: "Structural Consultant (NCD)",
          amount: "45895.5223880597",
        },
        {
          key: "17.1",
          catagory: "Consultants",
          description: "PT Consultant (Ultracon)",
          amount: "55748.9684328358",
        },
        {
          key: "17.11",
          catagory: "Consultants",
          description: "Electrical Consultant (Kosala)",
          amount: "39389.3129770992",
        },
        {
          key: "17.12",
          catagory: "Consultants",
          description: "Mechanical Consultant (Kapila)",
          amount: "32824.427480916",
        },
        {
          key: "17.13",
          catagory: "Consultants",
          description: "Landscape Designer (Belt Collins)",
          amount: "150000",
        },
        {
          key: "17.14",
          catagory: "Consultants",
          description: "Interior Designer (Wilson)",
          amount: "598000",
        },
        {
          key: "17.15",
          catagory: "Consultants",
          description: "Lighting Designer (Photonics)",
          amount: "10497.3384615385",
        },
        {
          key: "17.16",
          catagory: "Consultants",
          description: "LEED Consultant (Co-Energy)",
          amount: "38627.0033587786",
        },
        {
          key: "17.17",
          catagory: "Consultants",
          description: "Signage Consultant",
          amount: "0",
        },
        {
          key: "17.18",
          catagory: "Consultants",
          description: "JLL-Assets Management Fee",
          amount: "40533.3333333333",
        },
        {
          key: "17.19",
          catagory: "Consultants",
          description: "AV Consultant",
          amount: "2666.66666666667",
        },
        {
          key: "17.2",
          catagory: "Consultants",
          description: "JMBM Lawyers",
          amount: "11500",
        },
        {
          key: "17.21",
          catagory: "Consultants",
          description: "Light @ Work",
          amount: "39474",
        },
        {
          key: "17.22",
          catagory: "Consultants",
          description: "OPIC/BHP",
          amount: "50187.8299253731",
        },
        {
          key: "17.23",
          catagory: "Consultants",
          description: "Loan Origination Fees - IESC",
          amount: "20000",
        },
        {
          key: "17.24",
          catagory: "Consultants",
          description: "HNB Loan Processing Fees",
          amount: "125035.126",
        },
        {
          key: "17.241",
          catagory: "Consultants",
          description: "Consultancey Fee For Building-EWE",
          amount: "2305961.19432",
        },
        {
          key: "17.25",
          catagory: "Consultants",
          description: "ACS - Intergrated - QS related works",
          amount: "3333.33333333333",
        },
      ],
    },
    {
      key: 18,
      catagory: "Indirect Cost",
      description: "",
      amount: 13933401,
      breakdown: [
        {
          key: "18.1",
          catagory: "Indirect Cost",
          description: "Operating Supplies & Equipment (OS&E)",
          amount: "1392977.90666667",
        },
        {
          key: "18.2",
          catagory: "Indirect Cost",
          description: "Marriott Technical Service Fee",
          amount: "200000",
        },
        {
          key: "18.3",
          catagory: "Indirect Cost",
          description: "Apara Hospitality (Marriott Agreement Negotiation)",
          amount: "40000",
        },
        {
          key: "18.4",
          catagory: "Indirect Cost",
          description: "HVS Feasibility Study",
          amount: "27316",
        },
        {
          key: "18.5",
          catagory: "Indirect Cost",
          description: "License Fees ",
          amount: "5411.57113333333",
        },
        {
          key: "18.6",
          catagory: "Indirect Cost",
          description: "Stamp Duty on Issue of Shares",
          amount: "43144",
        },
        {
          key: "18.7",
          catagory: "Indirect Cost",
          description: "Interest /Exchange Losses During Construction",
          amount: "4730278.41126667",
        },
        {
          key: "18.8",
          catagory: "Indirect Cost",
          description: "Pre-Opening Expenses",
          amount: "672774.611866667",
        },
        {
          key: "18.9",
          catagory: "Indirect Cost",
          description: "Land/Building Cost",
          amount: "1985590.1348",
        },
        {
          key: "18.1",
          catagory: "Indirect Cost",
          description: "Fixed Assets - Right off all assets to whole Project",
          amount: "655700.804533333",
        },
        {
          key: "18.11",
          catagory: "Indirect Cost",
          description: "Over head ( Staff Salary)",
          amount: "3555051.06053333",
        },
        {
          key: "18.12",
          catagory: "Indirect Cost",
          description: "Labour salary",
          amount: "770286.306266667",
        },
      ],
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "5px",
        }}
      >
        <Row
          style={{ textAlign: "center", maxWidth: "1200px", display: "flex" }}
        >
          <Col span={24} style={{ zIndex: "9999" }}>
            <div>
              <h1 style={{ color: "#0842a0", fontSize: "2rem" }}>
                Weligama Bay - Marriott Resort & Spa
              </h1>
              <h2 style={{ color: "grey", fontSize: "1.5rem" }}>
                {"- budget -"}
              </h2>
            </div>
          </Col>
          <Col span={24}>
            <Table
              footer={() => (
                <div style={{ textAlign: "center" }}>
                  <strong>
                    <p>Total: $46,600,000</p>
                  </strong>
                </div>
              )}
              columns={columns}
              pagination={false}
              size="small"
              scroll={{
                x: 1200,
                y: "70vh",
              }}
              expandable={{
                expandedRowRender,
                // defaultExpandedRowKeys: ["1"],
                rowExpandable: (record) => record.breakdown.length !== 0,
              }}
              dataSource={budgetData}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
