import { ViewPdfUpload } from "../../utils/viewPdfUpload";
import { ArticleList } from "../article/articleList";

export const Template = () => {
  // Important
  // To be used as a template for articles
  // copy the file and edit

  const articleData = [
    {
      title: "Article Section Here",
      key: 1,
      item: [
        {
          key: 1,
          title: "Article Text",
          description: (
            <div>
              <p>{"Article Here"}</p>
            </div>
          ),
        },
        {
          key: 1,
          title: "Article with PDF",
          description: (
            <div>
              <ViewPdfUpload
                pdfURL={
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
              />
            </div>
          ),
        },
      ],
    },
  ];

  return <ArticleList articleData={articleData} />;
};
