import { DynamicMetaTags } from "../utils/dynamicMetaTags";
import { Main } from "./Main";

export const LandingPage = ({ helloWorld }) => {
  return (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <DynamicMetaTags ogTitle={`Marriott Case Study`} ogImage={""} />
      <div>
        <Main />
      </div>
    </div>
  );
};
