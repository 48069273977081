import { Button, Checkbox, Input, Popover } from "antd";
import { useState } from "react";

export const Feedback = () => {
  const { TextArea } = Input;

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const feedbackForm = (
    <div style={{ maxWidth: "300px" }}>
      <ul style={{ padding: "0px", listStyle: "none" }}>
        <li>
          <Checkbox>{"Inaccurate - doesn't match the section"}</Checkbox>
        </li>
        <li>
          <Checkbox>{"PDF or Image not loading"}</Checkbox>
        </li>
        <li>
          <Checkbox>{"Hard to understand - unclear"}</Checkbox>
        </li>
        <li>
          <Checkbox>
            {"Minor errors - formatting issues, typos, and / or broken links"}
          </Checkbox>
        </li>
        <li>
          <Checkbox>
            {"Other suggestions - ideas to improve the content"}
          </Checkbox>
        </li>
      </ul>
      <p>{"Share additional info or suggestions"}</p>
      <TextArea rows={4} />
      <div style={{ textAlign: "right" }}>
        <Button onClick={hide} style={{ margin: "5px" }} type="link">
          Cancel
        </Button>
        <Button style={{ margin: "5px" }} type="primary">
          Submit
        </Button>
      </div>
    </div>
  );

  return (
    <div style={{ textAlign: "center" }}>
      <Popover
        content={feedbackForm}
        placement="top"
        title="What is the issue with this selection?"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button size="small" type="link">
          <small>{"Give feedback about this article"}</small>
        </Button>
      </Popover>
    </div>
  );
};
