import { Card } from "antd";

export const ViewItem = ({ directoryItem }) => {
  return (
    <div>
      <Card
        bordered={false}
        size="small"
        title={directoryItem.title}
        // extra={<a href="#">More</a>}
        style={{
          width: "100%",
        }}
      >
        <p>{directoryItem.overview}</p>
      </Card>
    </div>
  );
};
