import { Avatar, Button, Col, List, Row } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";

export const Team = () => {
  const teamData = [
    {
      nameFirst: "Rachel",
      nameLast: "Rajaratnam",
      title: "Project Management",
      description: "Specialised in Project Managment",
      linkedin: "https://www.linkedin.com/in/rachel-rajaratnam/",
      whatsapp: "+94777322500",
      email: "test@email.com",
    },
    {
      nameFirst: "Nishantha ",
      nameLast: "Sooriyaarachchhi",
      title: "Project Management & Financing",
      description: "Specialised in Project Managment",
      linkedin:
        "https://www.linkedin.com/in/nishantha-sooriyaarachchi-851343103/",
      whatsapp: "+94777880297",
      email: "test@email.com",
    },
  ];
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "5px",
        }}
      >
        <Row style={{ maxWidth: "900px", display: "flex", width: "100%" }}>
          <Col span={24} style={{ zIndex: "9999" }}>
            <div style={{ textAlign: "center" }}>
              <p>Listed is the team who worked on the project.</p>
            </div>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button style={{ margin: "2px" }}>{"Engineers"}</Button>
            <Button style={{ margin: "2px" }}>{"Project Management"}</Button>
            <Button style={{ margin: "2px" }}>{"Site Supervisors"}</Button>
            <Button style={{ margin: "2px" }}>{"Procurement"}</Button>
            <Button style={{ margin: "2px" }}>{"Accounting"}</Button>
          </Col>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={teamData}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a
                      target={"_blank"}
                      rel={"noreferrer"}
                      href={item.linkedin}
                    >
                      Linkedin
                    </a>,
                    <a
                      target={"_blank"}
                      rel={"noreferrer"}
                      href={`https://wa.me/${item.whatsapp}?text=Hi ${item.nameFirst} found your contact on Marriott Case Study, keen to have a chat when you free.`}
                    >
                      <WhatsAppOutlined
                        style={{
                          fontSize: "18px",
                          verticalAlign: "middle",
                          color: "green",
                        }}
                      />
                    </a>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar src={`https://robohash.org/${item.nameFirst}`} />
                    }
                    title={`${item.nameFirst} ${item.nameLast}`}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
