import { Card } from "antd";
import { ContentUseFul } from "../../utils/contentUseful";
import { Feedback } from "../../utils/feedback";

export const ViewArticle = ({ viewArticle }) => {
  return (
    <Card
      id={viewArticle.title}
      size="small"
      extra={<ContentUseFul viewArticle={viewArticle} />}
      style={{
        width: "100%",

        marginTop: "37px",
      }}
      actions={[<Feedback />]}
    >
      <div
        style={{
          maxHeight: "60vh",
          overflow: "scroll",
        }}
      >
        {viewArticle.description}
      </div>
    </Card>
  );
};
